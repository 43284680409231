/* General Layout */
.terms-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: #f5f5f5;
}

.content-wrapper {
  display: flex;
  flex: 1;
  margin-top: 60px;
  width: 100%;
  padding: 20px;
}

/* Sidebar Styling */
.terms-sidebar {
  width: 280px;
  background-color: #2c3e50;
  padding: 25px;
  flex-shrink: 0;
  color: #ecf0f1;
  height: calc(100vh - 60px);
  position: sticky;
  top: 60px;
  overflow-y: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.terms-sidebar.hidden {
  display: none;
}

.terms-sidebar h1 {
  font-size: 1.75rem;
  margin-bottom: 25px;
  color: #ffffff;
  text-align: center;
}

/* Sidebar List Styling */
.terms-sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.terms-sidebar ul li {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  cursor: pointer;
  color: #ecf0f1;
  border-radius: 8px;
  transition: background 0.3s, color 0.3s;
  font-size: 18px;
  margin-bottom: 12px;
}

.terms-sidebar ul li:hover {
  background-color: #3b4a59;
}

.terms-sidebar ul li.active {
  background-color: #e74c3c;
  color: #ffffff;
  font-weight: bold;
}

/* Main Content Styling */
.terms-content-container {
  flex-grow: 1;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 900px;
  margin: 0 auto;
}

.back-button {
  background-color: #e74c3c;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 18px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background 0.3s;
}

.back-button:hover {
  background-color: #d13b2a;
}

.terms-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  color: #2c3e50;
  overflow-y: auto;
  line-height: 1.8;
}

.terms-content h2 {
  font-size: 32px;
  color: #2c3e50;
  margin-bottom: 30px;
  position: relative;
}

.terms-content h2::after {
  content: "";
  width: 60px;
  height: 4px;
  background-color: #e74c3c;
  position: absolute;
  bottom: -10px;
  left: 0;
}

.terms-content p {
  line-height: 1.8;
  color: #555555;
  margin-bottom: 20px;
  font-size: 18px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .terms-page {
    flex-direction: column;
  }

  .content-wrapper {
    flex-direction: column;
    padding: 10px;
  }

  .terms-sidebar {
    width: 100%;
    height: auto;
    position: relative;
    margin-bottom: 10px;
    border-radius: 0;
  }

  .terms-sidebar ul {
    display: flex;
    flex-wrap: nowrap;
  }

  .terms-sidebar ul li {
    flex: 1;
    justify-content: center;
    margin-bottom: 0;
    border-radius: 0;
  }

  .terms-content-container {
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }

  .terms-content {
    padding: 15px;
  }

  .back-button {
    width: 100%;
    padding: 15px;
    font-size: 20px;
  }
}