/* CommunitySidebar.css */

/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

/* Sidebar Toggle Button */
.sidebar-toggle-btn {
  position: fixed;
  bottom: 50px;
  left: 25px;
  background-color: #fff;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1001;
  display: none;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sidebar-toggle-btn:focus {
  outline: 2px solid #007bff;
}

/* Community Sidebar */
.community-sidebar {
  width: 15%;
  min-width: 200px;
  background-color: #f7f7f7;
  padding: 20px;
  box-shadow: 2px 0 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 60px;
  bottom: 0;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

/* Communities List */
.communities {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 100px);
}

/* Community Item */
.communities li {
  margin: 10px 0;
  padding: 12px 5px;
  border-radius: 50px;
  width: 100%;
  transition: background-color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.communities li.active {
  background-color: var(--color-light-accent);
  color: #fff;
}

.communities li:hover {
  background-color: #e0e0e0;
  transform: translateX(5px);
}

/* Sidebar Link */
.sidebar-link {
  text-decoration: none;
  color: inherit;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebar-link:focus {
  outline: 2px solid #007bff;
  border-radius: 5px;
}

/* Contact Us (Feedback) Container */
.contact-us-container {
  margin-top: auto;
  border-top: 1px solid #e0e0e0;
  padding-top: 15px;
  margin-bottom: 20px;
}

/* Contact Us (Feedback) Button */
.contact-us-button {
  width: 100%;
  padding: 12px 5px;
  border-radius: 50px;
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ff6333;
  transition: all 0.3s ease;
}

.contact-us-button:hover {
  background-color: rgba(255, 99, 51, 0.1);
  transform: translateX(5px);
}

.contact-us-button .sidebar-icon {
  margin-right: 10px;
  color: #ff6333;
}

/* Sidebar Icon */
.sidebar-icon {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.35rem;
  min-width: 24px;
}

/* Sidebar Label */
.sidebar-label {
  display: inline;
  font-size: 1rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .community-sidebar {
    width: 20%;
    padding: 15px;
  }

  .communities li {
    padding: 8px 12px;
  }

  .sidebar-icon {
    font-size: 1.3rem;
    margin-right: 8px;
  }
}

@media (max-width: 768px) {
  .community-sidebar {
    transform: translateX(-100%);
    width: 70%;
  }

  .community-sidebar.open {
    transform: translateX(0);
  }

  .sidebar-toggle-btn {
    display: block;
  }

  .communities li {
    padding: 12px 20px;
  }

  .sidebar-icon {
    font-size: 1.5rem;
    margin-right: 10px;
  }
}

@media (max-width: 480px) {
  .community-sidebar {
    width: 80%;
    padding: 10px;
  }

  .communities li {
    padding: 10px 15px;
  }

  .sidebar-icon {
    font-size: 1.2rem;
    margin-right: 8px;
  }

  .sidebar-label {
    display: none;
  }
}
