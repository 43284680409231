.media-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 aspect ratio */
    border-radius: 16px;
    overflow: hidden;
    background: #000;
    margin: 12px 0;
    border: 2px solid #e1e8ed;
    /* Light border similar to X's style */
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* Ensures content fills the container while maintaining aspect ratio */
    background: #000;
}

.volume-toggle {
    position: absolute;
    bottom: 16px;
    right: 16px;
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    font-size: 18px;
    transition: background-color 0.2s;
}

.volume-toggle:hover {
    background: rgba(0, 0, 0, 0.8);
}

.media-loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #536471;
    background: #f7f9f9;
    font-size: 16px;
}

.media-name {
    font-size: 14px;
    color: #536471;
    margin-left: 8px;
}