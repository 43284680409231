.organization-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin-top: 5%;
  background-color: #f4f5f7; /* Updated background for consistency */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; /* Consistent font */
}

.organization-page .content {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-top: 0;
  padding: 1.5%;
  flex-grow: 1;
  overflow-y: hidden;
  background-color: #fff; /* Updated to match modern styling */
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.organization-page .filter-container {
  display: flex;
  gap: 1.5%; /* Consistent spacing */
  margin-bottom: 2%;
}

.organization-page .filter-dropdown {
  position: relative;
  flex: 0.15;
}

.organization-page .filter-label {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transitions */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.organization-page .filter-label:hover {
  background-color: #ececec; /* Subtle hover effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Enhanced hover shadow */
}

.organization-page .filter-options {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  margin-top: 5px;
}

.organization-page .filter-option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.organization-page .filter-option:hover {
  background-color: #f0f0f0;
}

.organization-page .main-section {
  display: flex;
  gap: 2%;
  height: 100%;
  overflow-y: hidden;
  padding-bottom: 80px;
}

.organization-page .organizations-container {
  flex: 4;
  display: flex;
  flex-direction: column;
  gap: 1.5%;
  overflow-y: auto;
  padding-right: 1%;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
  border-radius: 15px;
  background-color: #fff; /* Consistent with other containers */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.organization-page .organization-card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  position: relative;
}

.organization-page .profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #ccc;
  margin-right: 15px;
  overflow: hidden;
}

.organization-page .profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.organization-page .organization-info {
  flex: 1;
}

.organization-page .organization-info h3 {
  margin: 0;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 0.5%;
  color: #333; /* Updated text color for readability */
}

.organization-page .organization-info span {
  display: block;
  color: #888;
  margin-top: 5px;
}

.organization-page .follow-button {
  position: absolute;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.organization-page .follow-button.following {
  background-color: #28a745; /* Green color when following */
}

.organization-page .follow-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.organization-page .follow-button.following:hover {
  background-color: #218838; /* Darker green on hover when following */
}

.organization-page .courses-container {
  flex: 2;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 1.5%;
  max-width: 40%;
  height: 100%;
  align-self: flex-start;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 20px;
}

.organization-page .courses-container::-webkit-scrollbar {
  display: none;
}

.organization-page .courses-title {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333; /* Consistent text color */
}

.organization-page .course-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
  width: 100%;
}

.organization-page .course-thumbnail {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 15px;
}

.organization-page .course-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.organization-page .course-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.organization-page .course-info .profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.organization-page .course-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.organization-page .course-details h2 {
  font-size: 1.1em;
  margin: 0;
  color: #333;
}

.organization-page .creator-name {
  font-size: 0.9em;
  color: #555;
  margin-top: 5px;
}

.organization-page .rating {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.organization-page .rating span {
  font-size: 0.9em;
  color: #555;
}

.organization-page .share-icon {
  margin-left: auto;
  color: #007bff;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.organization-page .share-icon:hover {
  transform: translateY(-2px);
}

@media (max-width: 768px) {
  .organization-page .content {
    margin-left: 0;
    padding: 2%;
  }

  .organization-page .main-section {
    flex-direction: column;
  }

  .organization-page .organizations-container,
  .organization-page .courses-container {
    width: 100%;
  }

  .organization-page .courses-container {
    margin-left: 0;
    margin-top: 2%;
    max-width: 100%;
  }
}