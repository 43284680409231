.question-thread {
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.post-container {
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
}

.post-header {
  display: flex;
  gap: 12px;
}

.profile-wrapper {
  position: relative;
  cursor: pointer;
}

.profile-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.options-menu {
  position: absolute;
  top: 50px;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  white-space: nowrap;
}

.options-menu div {
  padding: 8px 16px;
  cursor: pointer;
}

.options-menu div:hover {
  background: #f3f4f6;
}

.post-content {
  flex: 1;
}

.post-author {
  display: flex;
  align-items: center;
  gap: 8px;
}

.post-time {
  color: #6b7280;
  font-size: 0.875rem;
}

.post-body {
  margin-top: 8px;
  white-space: pre-wrap;
}

.more-button {
  color: #3b82f6;
  background: none;
  border: none;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
}

.more-button:hover {
  text-decoration: underline;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

.tag {
  background-color: #f3f4f6;
  color: #6b7280;
  padding: 4px 12px;
  border-radius: 9999px;
  font-size: 0.875rem;
}

.media-upload {
  margin-top: 12px;
}

.upload-icon {
  width: 20px;
  height: 20px;
  color: #6b7280;
  cursor: pointer;
}

.hidden {
  display: none;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.comment-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.comment-header .profile-picture {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.comment-box {
  display: flex;
  flex-direction: column;
}

.comment-box textarea {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.comment-box button {
  align-self: flex-end;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.comment-list {
  margin-top: 10px;
}

.comment {
  margin-bottom: 1em;
  margin-left: 2em;
}

.comment-actions span {
  margin-right: 10px;
  cursor: pointer;
  color: blue;
}

.comment-actions span:hover {
  text-decoration: underline;
}

/* Small screens */
@media (max-width: 768px) {
  .question-thread {
    padding: 12px;
    margin-bottom: 12px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  }

  .post-container {
    padding: 12px;
  }

  .profile-picture {
    width: 30px;
    height: 30px;
  }

  .post-header {
    flex-direction: column;
    gap: 8px;
  }

  .options-menu {
    top: 40px;
  }

  .tags-container {
    gap: 4px;
  }

  .tag {
    font-size: 0.75rem;
    padding: 3px 8px;
  }
}
