* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for the entire signup page */
.signup-page {
  display: flex;
  min-height: 100vh; /* full viewport height */
}

/* Left side: background, text, or image */
.signup-left {
  flex: 1;
  background-color: #983a1c;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.signup-form-logo-image {
  width: 250px;
  height: 250px; /* Crop the image because it's too long */
  object-fit: cover;
  object-position: center top;
  filter: drop-shadow(0 0 3px white);
}

/* Content inside the left panel */
.left-content {
  max-width: 400px;
  margin: 0 auto;
}
.left-content h1 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Georgia", serif;
}

/* Terms and conditions text */
.signup-terms-text {
  font-size: 0.75rem;
  color: #ffffff;
  text-align: center;
  margin-top: 1.5rem;
}

.signup-terms-text a {
  text-decoration: none;
  text-decoration: underline;
  font-weight: 500;
}

.signup-terms-text a:hover {
  text-decoration: underline;
}

/* Right side: form section */
.signup-right {
  flex: 1;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

/* Section containing the form and/or role selection */
.signup-form-section {
  width: 100%;
  max-width: 600px;
  background: #ffffff;
}

/* Role selection step container */
.signup-role-selection {
  text-align: center;
}

.signup-role-selection h1 {
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #333333;
  font-family: "Georgia", serif;
}

.signup-role-selection h2 {
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  color: #333333;
  margin-bottom: 1rem;
}

/* Container for role options (Student, Educator, etc.) */
.role-options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Individual role option box */
.role-option {
  background: #f9f9f9;
  border-radius: 6px;
  padding: 1.2rem;
  cursor: pointer;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  transition: border 0.2s ease;
}

.role-option:hover {
  border: 1px solid #cccccc;
}

.role-option span {
  font-size: 2rem;
  margin-right: 1rem;
}

.role-option-text h3 {
  margin: 0;
  font-size: 1rem;
  color: #333333;
}

.role-option-text p {
  margin: 0.2rem 0 0;
  font-size: 0.75rem;
  color: #666666;
}

/* Actual form for step 2 */
.signup-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.signup-form h1 {
  color: #333333;
  font-size: 1.5rem;
  font-family: "Georgia", serif;
  margin-bottom: 0.5rem;
}

/* Back button styling */
.signup-back-button {
  display: flex;
  align-items: center;
  max-width: max-content;
  background-color: transparent;
  border: none;
  color: #666666;
  font-size: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.signup-back-button:hover {
  color: #333333;
}

/* Input fields styling */
.input-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.signup-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 6px;
  border: 1px solid #cccccc;
  outline: none;
  transition: border 0.2s ease;
}

.required-asterisk {
  position: absolute;
  top: 5px;
  right: 10px;
  color: red;
  font-size: 14px;
  pointer-events: none;
}

.signup-input:focus {
  border: 1px solid #666666;
}

/* Error text styling */
.error-text {
  color: #e74c3c;
  font-size: 0.65rem;
}

/* Container for password + toggle button */
.password-container {
  position: relative;
  display: flex;
  align-items: center;
}

.signup-toggle-password-button {
  position: absolute;
  right: 1.4rem;
  background-color: transparent;
  border: none;
  color: #666666;
  cursor: pointer;
  font-size: 1.1rem;
}

/* Role-specific input container */
.signup-role-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

/* Inputs for role-specific details */
.signup-role-input {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.75rem;
  border-radius: 6px;
  border: 1px solid #cccccc;
  outline: none;
  transition: border 0.2s ease;
}

.signup-role-input:focus {
  border: 1px solid #666666;
}

/* Terms checkbox container */
.signup-terms-checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #666666;
}

.signup-terms-checkbox input {
  transform: scale(1.2);
}

.signup-terms-checkbox label {
  font-size: 0.75rem;
  margin-top: 12px;
  display: inline-block;
}

/* Submit button */
.signup-submit-button {
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.9rem;
  background-color: #007bff; /* Primary color */
  color: #ffffff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin-top: 0.5rem;
}

.signup-submit-button:hover {
  background-color: #0056b3; /* Darker shade of primary color */
}

/* Redirect text for login */
.signup-login-redirect {
  text-align: center;
  margin-top: 0.75rem;
  font-size: 0.75rem;
  color: #666; /* Neutral text color for surrounding text */
}

.login-redirect-button {
  background: none;
  border: none;
  color: #007bff; /* Blue color for link */
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;
}

.login-redirect-button:hover {
  text-decoration: underline;
  color: #0056b3; /* Darker blue on hover */
}

@media (max-width: 768px) {
  .signup-page {
    flex-direction: column;
  }

  .signup-right {
    padding: 2rem;
    padding-top: 0rem;
  }

  .signup-form-logo-image {
    width: 100px;
    height: 100px;
  }

  .signup-left {
    padding: 1rem;
  }
}
