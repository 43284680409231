.educator-page {
  display: flex;
  flex-direction: column;
  /* Let the page take up the full viewport height,
     but remove overflow: hidden so we can scroll if needed. */
  min-height: 100vh;
  margin-top: 3%;
  background-color: #f4f5f7;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow-x: hidden; /* Hide horizontal scroll if content is wide */
}

.educator-page .content {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-top: 2%;
  padding: 2%;
  flex-grow: 1;
  /* We can let the main content be scrollable if it overflows, or rely on internal containers. */
  /* overflow-y: auto; */
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* FILTER SECTION */
.educator-page .filter-container {
  display: flex;
  gap: 1%;
  margin-bottom: 2%;
}

.educator-page .filter-dropdown {
  position: relative;
  display: inline-block;
  width: 200px;
}

.educator-page .filter-label {
  display: flex;
  align-items: center;
  padding: 0.8em 1.5em;
  border: 1px solid #ddd;
  border-radius: 0.5em;
  background-color: #ffffff;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.educator-page .filter-label:hover {
  background-color: #ececec;
}

.educator-page .dropdown-icon {
  margin-left: auto;
  transition: transform 0.3s ease;
}

.educator-page .dropdown-icon.open {
  transform: rotate(180deg);
}

.educator-page .filter-options {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 0.5em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
}

.educator-page .filter-option {
  padding: 0.8em 1.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.educator-page .filter-option:hover {
  background-color: #f0f0f0;
}

/* MAIN SECTION WITH 2 COLUMNS (Educators & Courses) */
.educator-page .main-section {
  display: flex;
  gap: 2%;
  align-items: flex-start;  /* top-align both columns */
  height: 80vh;            /* fix them both to the same vertical size */
  flex-grow: 1;
  overflow: hidden;        /* the child containers each scroll internally */
}

/* EDUCATORS CONTAINER (left side) */
.educator-page .educators-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  overflow-y: auto;         /* scroll internally */
  padding-right: 1%;
  scrollbar-width: none;    /* Hide scrollbar on Firefox */
  -ms-overflow-style: none; /* Hide scrollbar on IE */
  height: 100%;             /* fill the parent (80vh) */
}

.educator-page .educators-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar on WebKit browsers */
}

/* EDUCATOR CARD STYLES */
.educator-page .educator-card {
  display: flex;
  align-items: center;
  background: #ffffff;
  padding: 1.5em;
  border-radius: 1em;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;
}
.educator-page .educator-card:hover {
  transform: translateY(-5px);
}

.educator-page .profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #cccccc;
  margin-right: 1em;
  overflow: hidden;
}

.educator-page .profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.educator-page .educator-info {
  flex: 1;
}

.educator-page .educator-info h3 {
  margin: 0;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 0.5%;
}

.educator-page .educator-info .educator-name,
.educator-page .educator-info .educator-subject {
  display: inline;
}

.educator-page .educator-info .dot-divider {
  display: inline;
  margin: 0 0.5%;
}

.educator-page .educator-info span {
  display: block;
  color: #888888;
}

/* FOLLOW BUTTON ON EDUCATOR CARD */
.educator-page .follow-button {
  position: absolute;
  right: 20px;
  background-color: #007bff;
  color: white;
  padding: 0.7em 1.2em;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}
.educator-page .follow-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

/* COURSES CONTAINER (right side) */
.educator-page .courses-container {
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: 1.5%;
  max-width: 33%;
  height: 100%;          /* match parent's 80vh */
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
  /* remove margin-bottom so it doesn't push beyond the container */
  padding: 20px; /* keep padding for spacing */
}

.educator-page .courses-container::-webkit-scrollbar {
  display: none; 
}

/* COURSES TITLE */
.educator-page .courses-title {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5em;
}

/* COURSES LIST */
.educator-page .courses-list {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

/* COURSE ITEM */
.educator-page .course-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 15px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08);
  width: 100%;
  transition: transform 0.3s ease;
}

.educator-page .course-item:hover {
  transform: translateY(-5px);
  cursor: pointer;
}

/* COURSE THUMBNAIL */
.educator-page .course-thumbnail {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 1em;
}

.educator-page .course-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* COURSE INFO */
.educator-page .course-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.educator-page .course-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.educator-page .course-details h2 {
  font-size: 1.1em;
  margin: 0 0 0.5em 0;
  color: #333;
}

.educator-page .creator-name {
  font-size: 0.9em;
  color: #777;
}

.educator-page .rating {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.educator-page .rating span {
  font-size: 0.9em;
  color: #555;
}

.educator-page .share-icon {
  margin-left: auto;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.educator-page .share-icon:hover {
  color: #0056b3;
}

/* Follow button on courses (if needed) */
.follow-button {
  background-color: #007bff;
  color: white;
  padding: 0.7em 1.2em;
  border: none;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.follow-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.follow-button.following {
  background-color: #f24E1E; /* or green, etc. */
}

.follow-button.following:hover {
  background-color: #f24E1E;
  transform: translateY(-2px);
}

/* RESPONSIVE */
@media (max-width: 768px) {
  .educator-page .content {
    margin-left: 0;
    padding: 2%;
  }

  .educator-page .main-section {
    flex-direction: column;
    height: auto; /* let it size naturally on smaller screens */
  }

  .educator-page .educators-container,
  .educator-page .courses-container {
    width: 100%;
    height: auto; /* each container sizes naturally */
  }

  .educator-page .courses-container {
    margin-left: 0;
    margin-top: 2%;
    max-width: 100%;
  }
}
