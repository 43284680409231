.profile-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 100vh;
}

.profile-main-content {
  display: flex;
  margin-top: -70px;
  /* Adjust margin to ensure it does not overlap with the TopNavBar */
}

.profile-container {
  flex-grow: 1;
  padding: 20px;
  margin-left: 250px;
  /* Adjust depending on your sidebar width */
  background-color: #f5f5f5;
  font-family: Arial, sans-serif;
  padding-bottom: 20px;
  /* Added padding to the bottom */
  margin-top: 20px;
  /* Ensure content starts below the fixed navbar */
}

.profile-banner {
  width: 100%;
  height: 200px;
  background-color: #ddd;
  /* Placeholder for the banner image */
  margin-bottom: 20px;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.banner-upload-label {
  cursor: pointer;
  color: #888;
}

.banner-upload-label input {
  display: none;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-picture {
  margin-right: 20px;
  background-color: #ddd;
  padding: 10px;
  /* Adjust to control inner spacing */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.profile-picture img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.profile-details h1 {
  margin: 0;
  font-size: 24px;
}

.profile-details p {
  font-size: 14px;
  color: #666;
}

.profile-stats {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  font-size: 14px;
}

.profile-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.button-primary {
  background-color: #ff4500;
}

.button-secondary {
  background-color: #333;
}

.edit-button,
.message-button,
.follow-button {
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  border-radius: 20px;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  /* Responsive grid */
  gap: 20px;
  /* Spacing between items */
  padding: 20px;
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.course-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 400px;
  /* Fixed height for uniformity */
  overflow: hidden;
  /* Prevent overflow */
  padding: 0;
  transition: transform 0.2s, box-shadow 0.2s;
  position: relative;
}

.course-thumbnail {
  width: 95%;
  /* Full width of the course item */
  height: 50%;
  /* Take up 70% of the course item height */
  overflow: hidden;
  /* Prevent the image from spilling out */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 12px 0 0;
  /* Rounded top corners */
}

.course-thumbnail img {
  width: 100%;
  /* Stretch to fit the width of the thumbnail */
  height: 100%;
  /* Stretch to fit the height */
  object-fit: cover;
  /* Ensure the image covers the container without distortion */
  display: block;
  /* Remove inline whitespace issues */
}

.course-info {
  flex-grow: 1;
  /* Use the remaining space for content */
  padding: 10px;
  text-align: center;
  background-color: #f9f9f9;
  /* Optional background color */
  width: 100%;
  box-sizing: border-box;
}

.course-info h3 {
  font-size: 1em;
  margin: 0;
  color: #333;
  overflow: hidden;
  /* Prevent text overflow */
  text-overflow: ellipsis;
  /* Add ellipsis for long text */
  white-space: nowrap;
  /* Prevent wrapping */
}

.course-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
}

.course-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.course-details h2 {
  font-size: 1.1em;
  margin: 0 0 0.5em 0;
  color: #333;
}

.rating {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.course-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.courses-title {
  text-align: center;
  font-size: 1.75em;
  font-weight: bold;
  margin-bottom: 30px;
  margin-top: 25px;
}

.creator-name {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.rating span {
  font-size: 0.9em;
  color: #555;
}

.share-icon {
  margin-left: auto;
  color: #007bff;
  cursor: pointer;
}

.profile-extra {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.extra-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.profile-page .tabs {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.profile-page .tab {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  /* Add transition for smooth effect */
}

.profile-page .tab.current {
  color: lightgrey;
}

.profile-page .tab:hover {
  background-color: #007bff;
  color: white;
}

.profile-tabs a:hover {
  background-color: #f1f1f1;
}

.profile-content {
  margin-top: 20px;
}

.profile-section {
  margin-bottom: 20px;
}

.post {
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
}

.post-stats {
  display: flex;
  gap: 10px;
  font-size: 14px;
  color: #666;
}

/* Ensuring TopNavBar and CommunitySidebar stay fixed during scroll */
.top-nav-bar {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.community-sidebar {
  position: fixed;
  top: 60px;
  /* Adjust to be below the TopNavBar */
  left: 0;
  width: 250px;
  /* Adjust based on the sidebar width */
  height: calc(100% - 60px);
  /* Adjust based on the TopNavBar height */
  overflow-y: auto;
  background-color: #fff;
  border-right: 1px solid #ccc;
  z-index: 999;
}

/* Ensuring the profile page content starts below the fixed navbar */
.profile-container {
  margin-top: 70px;
  /* Adjust based on the height of the TopNavBar */
}

@media (max-width: 768px) {
  .profile-container {
    margin-left: 0px;
  }
}
