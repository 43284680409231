/* Background animation */
@keyframes moveBackground {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

/* Main Container */
.access-page-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Background Gradient */
.access-page-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f5f7fa;
    background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
        linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
    background-size: 20px 20px;
    animation: gradient-diagonal-animation 60s linear infinite;
}

/* Keyframe for background animation */
@keyframes gradient-diagonal-animation {
    0% {
        background-position: 0 0;
    }

    100% {
        background-position: 100% 50%;
    }
}

/* Glass Effect Container */
.glass-container {
    width: 100%;
    max-width: 28rem;
    padding: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    background-color: rgba(255, 244, 234, 0.1);
    border-radius: 1rem;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 244, 234, 0.2);
    text-align: center;
    z-index: 1;
}

/* Logo */
.logo {
    width: 16rem;
    height: auto;
    animation: moveBackground 5s ease-in-out infinite alternate;
}

/* Title and Text */
.title {
    font-size: 1.875rem;
    font-weight: bold;
    color: #dd6317;
    text-align: center;
    margin-bottom: 0.2rem;
}

.description {
    color: #1b1b1b;
    text-align: center;
    margin-bottom: 2rem;
}

/* Updated Input Box */
.input {
    width: 100%;
    padding: 0.75rem;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(41, 40, 40, 0.4);
    color: rgb(0, 0, 0);
    border-radius: 0.5rem;
    text-align: center;
    font-size: 1rem;
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.input:focus {
    outline: none;
    border-color: rgba(224, 154, 109, 0.8);
    box-shadow: 0 4px 8px rgba(224, 154, 109, 0.4);
    background-color: rgba(255, 255, 255, 0.25);
}

/* Button */
.submit-button {
    width: 100%;
    padding: 0.75rem;
    background: linear-gradient(135deg, #E09A6D, #7C4A33, #D37C4A);
    color: white;
    font-weight: 600;
    border-radius: 0.5rem;
    transition: all 0.4s ease;
    transform: scale(1);
    box-shadow: 0 4px 15px rgba(224, 154, 109, 0.4);
    background-size: 200% 200%;
}

.submit-button:hover {
    background: linear-gradient(135deg, #4A90A4, #33657C, #1E3F4B);
    transform: scale(1.025);
    box-shadow: 0 8px 25px rgba(51, 101, 124, 0.7),
        0 0 10px rgba(74, 144, 164, 0.8);
    border: 2px solid rgba(74, 144, 164, 0.7);
}

/* Active/Pressed Effect */
.submit-button:active {
    transform: scale(0.98);
    box-shadow: 0 3px 10px rgba(224, 154, 109, 0.5);
}

/* Optional Gradient Animation */
@keyframes gradient-shift {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

/* Apply Gradient Animation */
.submit-button {
    animation: gradient-shift 5s ease infinite;
}

/* Error Message */
.error-message {
    color: #a32121;
    text-align: center;
    font-size: 0.875rem;
    margin-top: 0.75rem;
}