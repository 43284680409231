.question-actions {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 10px;
}

.question-actions button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 10px;
}

.question-actions .debug {
  display: block;
}

.like-dislike {
  display: flex;
  align-items: center;
}

.like-icon,
.dislike-icon {
  font-size: 20px;
}

.like-count {
  margin: 0 5px;
}

.interaction-btn.liked,
.interaction-btn.liked svg {
  color: #00aa00;
}

.interaction-btn.disliked,
.interaction-btn.disliked svg {
  color: #ff0000;
}

.interaction-btn {
  display: flex;
  align-items: center;
  gap: 5px;
}

.interaction-btn.self {
  cursor: not-allowed;
}

.interaction-btn .icon {
  color: #555;
}

/* Target the element with the class 'postAuthor you' */
.postAuthor.self:after {
  content: " (You)";
  color: rgb(66, 124, 0);
  /* font-weight: normal; */
}

/* Some cool share animation stuff */
@keyframes shareAnimation {
  0% {
    transform: translateY(0) rotate(0deg);
    color: inherit;
  }

  25% {
    transform: translateY(-10px) rotate(90deg);
    color: var(--aColorA);
  }

  50% {
    transform: translateY(-15px) rotate(180deg);
    color: var(--aColorB);
  }

  75% {
    transform: translateY(-10px) rotate(270deg);
    color: var(--aColorA);
  }

  100% {
    transform: translateY(0) rotate(360deg);
    color: inherit;
  }
}

.share-button.animate,
.share-button.animate svg {
  animation: shareAnimation 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  --aColorA: rgb(209, 150, 0);
  --aColorB: rgb(138, 100, 5);
}
