.seminars-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  margin-top: 3%;
  background-color: #f4f5f7;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.seminars-page__content {
  display: flex;
  flex-direction: column;
  margin-left: 15%;
  margin-top: 1%;
  padding: 2%;
  flex-grow: 1;
  overflow: visible;
  min-height: calc(
    100vh - 3% - 1% - 2%
  ); /* Ensures the content area fills the viewport */
}

.seminars-page__filters {
  display: flex;
  gap: 1%;
  margin-bottom: 2%;
}

.seminars-page__custom-dropdown-button {
  padding: 0.8em 1.5em;
  border: none;
  border-radius: 0.5em;
  background-color: #fff;
  color: black;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}

.seminars-page__custom-dropdown-button span {
  flex-grow: 1;
  color: black;
}

.seminars-page__custom-dropdown-button::after {
  content: "▼";
  font-size: 0.8em;
  margin-left: 10px;
  color: black;
}

.seminars-page__custom-dropdown-options {
  margin-top: 0.5em;
  background-color: #fff;
  border-radius: 0.5em;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0.5em 0;
  position: absolute;
  z-index: 1000;
  min-width: 160px;
}

.seminars-page__custom-dropdown-options li {
  padding: 0.5em 1.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.seminars-page__custom-dropdown-options li:hover {
  background-color: #f0f0f0;
}

.seminars-page__main-section {
  display: flex;
  gap: 2%;
  height: calc(
    100vh - 16% - 2% - 1%
  ); /* Reduces the height to remove the gap at the bottom */
  flex-grow: 1; /* Ensures the main section stretches to fill the available space */
}

.seminars-page__seminars-container {
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  overflow-y: auto;
  padding-right: 1%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.seminars-page__seminars-container::-webkit-scrollbar {
  display: none;
}

.seminars-page__seminar-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 1.5em;
  border-radius: 1em;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.3s ease;
}

.seminars-page__seminar-item:hover {
  transform: translateY(-5px);
}

.seminars-page__seminar-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 0.8em;
  overflow: hidden;
  margin-right: 1em;
}

.seminars-page__seminar-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seminars-page__seminar-details {
  flex: 1;
}

.seminars-page__seminar-title {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  margin: 0 0 0.5em 0;
}

.seminars-page__creator-name {
  font-size: 0.9em;
  color: #777;
  margin-bottom: 0.5em;
}

.seminars-page__seminar-info {
  font-size: 0.9em;
  color: #555;
}

.seminars-page__seminar-actions {
  align-items: center;
  justify-content: center;
  height: 100%;
  align-content: space-around;
}

.seminars-page__join-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 0.7em 1.2em;
  border-radius: 0.5em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.seminars-page__join-button:hover {
  background-color: #0056b3;
}

.seminars-page__courses-container {
  flex: 1.2;
  padding: 2%;
  background-color: #fff;
  border: none;
  border-radius: 1em;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-left: 2%;
  overflow-y: auto;
  max-height: calc(100vh - 16% - 2% - 1%);
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.seminars-page__courses-container::-webkit-scrollbar {
  display: none;
}

.seminars-page__courses-title {
  text-align: center;
  font-size: 1.5em;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5em;
}

.seminars-page__courses-list {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.seminars-page__course-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 1em;
  border-radius: 1em;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.seminars-page__course-item:hover {
  transform: translateY(-5px);
}

.seminars-page__course-thumbnail {
  width: 100%;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 0.8em;
  overflow: hidden;
  margin-bottom: 1em;
}

.seminars-page__course-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.seminars-page__course-info {
  display: flex;
  align-items: center;
  gap: 1em;
}

.seminars-page__profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.seminars-page__course-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.seminars-page__course-details h2 {
  font-size: 1.2em;
  margin: 0 0 0.5em 0;
  color: #333;
}

.seminars-page__creator-name {
  font-size: 0.9em;
  color: #777;
}

.seminars-page__rating {
  display: flex;
  align-items: center;
  gap: 0.5em;
  margin-top: 0.5em;
}

.seminars-page__rating span {
  font-size: 0.9em;
  color: #555;
}

.seminars-page__share-icon {
  color: #007bff;
  cursor: pointer;
}

.seminars-page__share-icon:hover {
  color: #0056b3;
}

/* Role modal specific to seminars-page */
.seminars-page__role-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.seminars-page__role-modal-content {
  background-color: #fff;
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  position: relative;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.seminars-page__close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
}

.seminars-page__role-modal-content h2 {
  margin-top: 0;
  font-size: 24px;
  color: #333;
}

.seminars-page__role-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.seminars-page__role-option {
  text-align: center;
  flex: 1;
}

.seminars-page__role-option h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.seminars-page__create-account-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.seminars-page__create-account-button:hover {
  background-color: #0056b3;
}

/* Media queries for laptop screens */
@media (max-width: 1440px) {
  .seminars-page__content {
    margin-left: 250px;
    padding: 15px;
  }

  .seminars-page .courses__container {
    height: calc(100vh - 200px);
    /* Adjust height for laptops */
  }
}

/* Media queries for smaller laptops */
@media (max-width: 1024px) {
  .seminars-page__content {
    margin-left: 250px;
    padding: 10px;
  }
  .seminars-page .courses__container {
    height: auto;
    min-height: 100vh;
    /* Adjust height for smaller laptops */
  }
}

@media (max-width: 768px) {
  .seminars-page__main-section {
    flex-direction: column;
  }

  .seminars-page__filters {
    flex-wrap: wrap;
    gap: 1rem;
  }

  .seminars-page__content {
    flex-direction: column;
    padding: 10px;
    margin-top: 7rem;
    margin-left: 0;
  }

  .seminars-page__seminars-container {
    flex-wrap: wrap;
    flex-direction: row;
    gap: 1rem; /* Adjust spacing between items */
  }

  .seminars-page__seminar-item {
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 calc(50% - 1rem); /* Two items per row with spacing */
    max-width: calc(50% - 1rem); /* Ensure consistent sizing */
  }

  .seminars-page .courses__container {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    height: auto;
    /* Adjust height for mobile */
    max-height: calc(300vh - 200px);
    /* Dynamically adjust height */
  }
}
