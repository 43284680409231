/* Global Styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
}

/* Top Navigation Bar */
.top-nav-bar {
  position: fixed;
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-light-primary);
  padding: 0 20px;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-nav-bar::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background-color: var(--color-light-primary);
}

/* Brand Container */
.top-nav-bar__brand-container {
  display: flex;
  align-items: center;
}

.top-nav-bar__brand-logo {
  height: 90px;
  width: auto;
  min-width: 90px;
  cursor: pointer;
}

/* Action Items */
.top-nav-bar__action-items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-left: 20px;
}

/* Search Container */
.top-nav-bar__search-container {
  display: flex;
  align-items: center;
  position: relative;
  width: 40%;
  margin: 0 20px;
}

.top-nav-bar__search-input {
  width: 100%;
  padding: 10px 40px 10px 15px;
  border-radius: 20px;
  border: 1px solid #ccc;
  height: 40px;
  box-sizing: border-box;
}

.top-nav-bar__search-icon {
  position: absolute;
  right: 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #979797;
  padding: 0 15px;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  z-index: 1;
}

.top-nav-bar__search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #ddd;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 8px;
}

.search-results-header {
  font-weight: bold;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #2c3e50;
}

.search-result-item {
  padding: 12px 15px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  color: #2c3e50;
}

.search-result-item:hover {
  background-color: #f0f0f0;
}

/* Dropdown */
.top-nav-bar__dropdown {
  position: relative;
  display: flex;
  align-items: center;
  z-index: 2;
  height: 100%;
  padding: 0 15px;
}

.top-nav-bar__dropdown button {
  background-color: #e0e0e0;
  border: none;
  padding: 3px 15px;
  font-size: 22px;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
}

.top-nav-bar__dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--color-light-primary);
  min-width: 150px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 3;
  border-radius: 0 0 20px 20px;
}

.top-nav-bar__dropdown-content a {
  color: #000;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
}

.top-nav-bar__dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Buttons */
.top-nav-bar__study-group-button,
.top-nav-bar__create-button {
  background-color: var(--color-light-accent);
  color: var(--color-light-primary);
  border: 1px solid var(--color-light-accent-secondary);
  padding: 8px 15px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease,
    transform 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.top-nav-bar__study-group-button:hover,
.top-nav-bar__create-button:hover {
  background-color: var(--color-light-accent-secondary);
  color: var(--color-light-primary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.top-nav-bar__study-group-button:active,
.top-nav-bar__create-button:active {
  background-color: var(--color-light-accent);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}

.top-nav-bar__study-group-button svg,
.top-nav-bar__create-button svg {
  margin-left: 8px;
  color: inherit;
  font-size: 1rem;
}

/* Icons */
.top-nav-bar__icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
}

.top-nav-bar__icon {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 24px;
  text-align: center;
  padding: 5px;
  transition: color 0.3s;
}

.top-nav-bar__icon svg {
  color: #555;
  font-size: 20px;
}

.top-nav-bar__icon-label {
  margin-left: 8px;
  font-size: 14px;
  color: #333;
}

.top-nav-bar__icon:hover svg,
.top-nav-bar__icon:hover .top-nav-bar__icon-label {
  color: var(--color-light-accent2);
}

/* Profile and Notification Sections */
.top-nav-bar__profile-section,
.top-nav-bar__notification-section {
  position: relative;
}

/* Profile and Notification Dropdowns */
.top-nav-bar__notification-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: var(--color-light-primary);
  color: #333;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  width: 280px;
  max-height: 400px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 1000;
}

.top-nav-bar__profile-dropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
  background-color: var(--color-light-primary);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  color: #333;
  position: absolute;
  top: 100%;
  right: 0;
}

.no-notifications {
  padding: 10px;
  color: #666;
  text-align: center;
}

/* Dropdown Links */
.top-nav-bar__profile-dropdown a,
.top-nav-bar__notification-dropdown a {
  padding: 10px 20px;
  text-decoration: none;
  color: #333;
  display: block;
}

.top-nav-bar__profile-dropdown a:hover,
.top-nav-bar__notification-dropdown a:hover {
  background-color: #f0f0f0;
}

/* Profile Dropdown */
.profile-divider {
  border: none;
  border-top: 1px solid #ddd;
  margin-bottom: -4px;
}

.profile-dropdown-item,
.profile-dropdown-item:not(button) {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 15px;
  border-radius: 8px;
  transition: background-color 0.3s;
  text-decoration: none;
  font-size: 14px;
  color: #333;
}

.profile-dropdown-item:hover {
  background-color: #f0f0f0;
}

.dropdown-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #666;
}

.arrow-icon {
  color: #999;
  font-size: 12px;
}

.logout,
.logout .dropdown-icon,
.logout .arrow-icon {
  color: var(--color-light-accent);
}

/* Notification Item */
.notification-item {
  border: none;
  border-radius: 8px;
  padding: 8px;
  /* Reduced padding to make the item smaller */
  margin-bottom: 8px;
  /* Reduced margin to minimize spacing between notifications */
  background-color: var(--color-light-primary);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.notification-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.notification-item.unread {
  background-color: #e6f7ff;
  font-weight: 600;
  padding: 8px;
}

.notification-item.read {
  background-color: #fafafa;
  opacity: 0.9;
  padding: 8px;
}

.notification-message {
  margin-bottom: 4px;
  /* Reduced margin to make it more compact */
  font-size: 14px;
  /* Decreased font size */
  color: #333;
}

.notification-timestamp {
  font-size: 12px;
  /* Decreased font size to fit it in one line */
  color: #666;
}

/* Responsive Adjustments */

/* Tablets */
@media (max-width: 1024px) {
  .top-nav-bar__search-container {
    width: 35%;
  }

  .top-nav-bar__action-items {
    margin-left: 15px;
  }

  .top-nav-bar__icons {
    gap: 15px;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .top-nav-bar {
    flex-direction: row;
    height: auto;
    padding: 10px 15px;
  }

  .top-nav-bar__study-group-button svg {
    margin-left: 0;
  }

  .top-nav-bar__brand-container,
  .top-nav-bar__action-items,
  .top-nav-bar__icons {
    justify-content: space-between;
    margin: 5px 0;
  }

  .top-nav-bar__search-container {
    width: 100%;
    margin: 10px 0;
  }

  .top-nav-bar__icons {
    gap: 10px;
  }

  .top-nav-bar__icon {
    font-size: 20px;
    padding: 5px;
  }

  .top-nav-bar__icon-label {
    display: none;
    /* Hide labels on smaller screens */
  }
}

/* Extra Small Devices (Portrait Phones) */
@media (max-width: 480px) {
  .top-nav-bar__search-container {
    width: 100%;
  }

  .top-nav-bar__search-input {
    padding: 8px 35px 8px 10px;
    height: 35px;
  }

  .top-nav-bar__search-icon {
    padding: 0 10px;
  }

  .top-nav-bar__dropdown button {
    padding: 8px 15px;
    font-size: 14px;
  }

  .top-nav-bar__study-group-button,
  .top-nav-bar__create-button {
    justify-content: center; /* Center the icon */
    padding: 0.5em;
  }

  .top-nav-bar__study-group-button svg,
  .top-nav-bar__create-button svg {
    margin-left: 0px;
  }

  .top-nav-bar__create-button span {
    display: none; /* Hide the text */
  }

  .top-nav-bar__profile-dropdown,
  .top-nav-bar__notification-dropdown {
    min-width: 150px;
  }
}
