/* src/components/StudentPage/StudentPage.css */
.student-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .logo {
    width: 250px;
    height: auto;
    margin-bottom: 0px;
  }
  
  .student-page h2 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .form-subtitle {
    margin-bottom: 20px;
    color: #666;
  }
  
  .student-page form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
  }
  
  .student-page form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .student-page form button {
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .student-page form button:hover {
    background-color: #555;
  }
  