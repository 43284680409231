/* Global styling for larger screens */
.home-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.home-page .content {
  display: flex;
  flex-direction: column;
  margin-left: 250px;
  margin-top: 0px;
  padding: 20px;
  flex-grow: 1;
  overflow: hidden;
}

/* Left Column */
.home-page .left-column {
  display: flex;
  flex-direction: column;
  flex: 3;
  gap: 20px;
}

.home-page .top-container {
  display: flex;
  margin-top: 35px;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  z-index: 10;
  padding: 10px 20px;
  border-radius: 15px;
  height: 80px;
  box-sizing: border-box;
  overflow: hidden;
}

.home-page .top-container > * {
  flex-shrink: 1;
  max-width: calc(100% / 1.5);
}

.home-page .study-group-section {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  padding-right: 0;
  height: 100%;
}

.study-group-list-wrapper {
  display: flex;
  align-items: center;
  flex-grow: 1;
  max-width: 85%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  padding: 0 10px;
}

.study-group-list-wrapper::-webkit-scrollbar {
  display: none;
}

.study-group-list-container {
  display: flex;
  align-items: center;
  gap: 5px;
  height: 60px;
  width: 100%; /* Make the container span the full width of its parent */
  max-width: 100%; /* Prevent the container from exceeding its parent width */
  box-sizing: border-box; /* Include padding and borders in width */
}

.study-group-list {
  display: flex;
  gap: 5px;
  padding: 10px;
  overflow-x: auto;
}

.study-group-item {
  position: relative;
  margin-top: 0;
  background: var(--color-light-primary);
  padding: 10px 20px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease,
    background-color 0.3s ease, color 0.3s ease;
  color: var(--color-light-text);
  white-space: nowrap;
  font-size: 0.9rem;
}

.study-group-item.selected {
  background-color: var(--color-light-accent);
  color: var(--color-light-primary);
}

.study-group-item.disabled {
  cursor: not-allowed;
}

.study-group-item.disabled:hover {
  background-color: var(--color-light-secondary);
}

.study-group-item:hover {
  background-color: var(--color-light-accent2-secondary);
  color: var(--color-light-primary);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}
.group-description {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.scroll-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.1em;
  color: #ffffff;
  transition: color 0.3s;
  height: 60px;
}

.scroll-button:hover {
  color: var(--color-light-accent2-secondary);
}

.home-page .post-button-container {
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 0;
  padding-bottom: 0;
  flex-shrink: 0;
  justify-content: center;
}

.home-page .main-content {
  display: flex;
  height: calc(100vh - 160px);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-left: 0px;
  padding: 0px;
}

.home-page .main-content::-webkit-scrollbar {
  display: none;
}

.home-page .feed-and-courses {
  display: flex;
  align-items: stretch;
  gap: 25px;
  width: 100%;
}

.home-page .feed-section {
  flex: 2;
  margin-left: 0px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-bottom: 2.5em;
  height: auto;
}

.home-page .feed-section::-webkit-scrollbar {
  display: none;
}

.home-page .sort-by-container {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  padding-bottom: 10px;
}

.home-page .question-list-container {
  overflow: hidden;
  padding-top: 10px;
}

.home-page .courses-section {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--color-light-primary);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  height: 100%;
}

.home-page .courses-section::-webkit-scrollbar {
  display: none;
}

/* Media queries for laptop screens */
/* @media (max-width: 1440px) {
  .home-page .content {
    margin-left: 250px;
    padding: 15px;
  }

  .home-page .courses-section {
    height: calc(100vh - 200px);
    margin-top: 20px;
  }
} */

/* Media queries for smaller laptops */
@media (max-width: 1440px) {
  .home-page .content {
    margin-left: 250px;
    margin-top: 25px;
    padding: 10px;
  }

  .home-page .feed-and-courses {
    gap: 15px;
  }

  .home-page .courses-section {
    height: auto;
    min-height: 100vh;
    margin-top: 10px;
  }
}

/* Media queries for tablets and mobile screens */
@media (max-width: 768px) {
  .home-page .content {
    flex-direction: column;
    padding: 10px;
    margin-left: 0;
    margin-top: 100px;
  }

  .home-page .main-content {
    flex-direction: column;
  }

  .home-page .feed-and-courses {
    flex-direction: column;
  }

  .home-page .feed-section {
    width: 100%;
  }

  .home-page .courses-section {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
    height: auto;
    max-height: calc(300vh - 200px);
    max-width: none;
  }

  .home-page .courses-title {
    margin-top: 0px;
  }
}

/* Styling for mobile screen sizes */
@media (max-width: 480px) {
  .home-page .top-container {
    flex-direction: row;
    height: auto;
  }

  .home-page .main-content {
    height: auto;
  }

  .home-page .feed-section {
    padding: 15px;
  }

  .home-page .courses-section {
    height: auto;
    /* Allow height to adjust on smaller mobile screens */
  }
}

.right-column {
  position: sticky;
  top: 20px;
  /* Adjust based on your top navigation height */
  height: calc(100vh - 80px);
  /* Adjust based on your layout */
  display: flex;
  flex-direction: column;
  max-width: 400px;
}

.home-page .courses-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.home-page .course-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.home-page .course-item:hover {
  transform: translateY(-5px);
}

.home-page .course-thumbnail {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.home-page .course-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-page .course-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-page .profile-picture {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.home-page .course-details {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.home-page .course-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.home-page .course-details h2 {
  font-size: 1em;
  margin: 0;
}

.home-page .courses-title {
  text-align: center;
  font-size: 1.75em;
  font-weight: bold;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  padding: 10px 0;
  z-index: 1;
}

.home-page .creator-name {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.home-page .rating {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 5px;
}

.home-page .rating span {
  font-size: 0.9em;
  color: #555;
}

.home-page .share-icon {
  margin-left: auto;
  color: #007bff;
  cursor: pointer;
}

.home-page .question-item {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 20px;
  transition: background-color 0.3s;
}

.home-page .question-item:hover {
  background-color: #f9f9f9;
}

.home-page .question-item .question-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.home-page .question-item .question-header h2 {
  font-size: 1.2em;
  margin: 0;
}

.home-page .question-item .question-header .question-meta {
  font-size: 0.9em;
  color: #555;
}

.home-page .question-item .question-body {
  font-size: 1em;
  color: #333;
}

.home-page .question-item .question-actions {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
}

.home-page .question-item .question-actions .action-button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 1em;
  padding: 5px 10px;
  transition: color 0.3s;
}

.home-page .question-item .question-actions .action-button:hover {
  color: #0056b3;
}
