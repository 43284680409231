.event-creation-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 7%; /* Reduced margin for better alignment */
  margin-left: 10%;
  padding: 0;
  height: auto;
}

.event-card {
  background-color: #ffffff;
  border-radius: 12px; /* Smooth corners */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
  width: 120%; /* Adjusted width for better fit */
  max-width: 1500px; /* Maximum width for larger screens */
  padding: 20px; /* Adjusted padding for better spacing */
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  border: 1px solid #e6e6e6; /* Light border for a clean edge */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds height */
  margin: 0 auto; /* Center the card horizontally */
}

.event-image-container {
  width: 100%;
  height: 250px; /* Adjusted height for a better proportion */
  background-color: #e0e0e0;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px; /* Added space between image and form fields */
}

.event-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  border-radius: 8px;
  transition: transform 0.3s ease;
}

.event-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increased gap for a more spacious layout */
}

.event-title-input,
.info-group input,
.event-description {
  width: 100%;
  padding: 10px; /* Adjusted padding for better spacing */
  font-size: 1.2rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.event-info {
  display: flex;
  align-items: center; /* Align items vertically */
  gap: 20px; /* Consistent gap between inputs */
  margin-top: 10px;
  flex-wrap: wrap; /* Allow wrapping for smaller screens */
}

.info-group {
  display: flex;
  align-items: center;
  gap: 5px; /* Small gap between icon and input */
}

.info-group input {
  flex-grow: 1; /* Let input fields take up remaining space */
  padding: 10px;
  font-size: 1.2rem;
  border: 1px solid #ced4da;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  width: 100%; /* Ensure inputs take full width of the container */
  box-sizing: border-box; /* Include padding and border in width */
}

.info-group i {
  font-size: 1.5rem; /* Adjust icon size for better visual balance */
  color: #495057; /* Color for icons */
}

.event-description {
  height: 150px;
  resize: vertical; /* Allow resizing of the description box */
}

.event-buttons {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.save-event-button {
  background-color: #28a745;
  color: #fff;
  padding: 10px 20px; /* Adjusted padding for a more balanced button */
  border: none;
  border-radius: 8px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, transform 0.3s ease;
}

.save-event-button:hover {
  background-color: #218838;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Media Queries for Responsive Design */

@media (max-width: 1024px) {
  .event-card {
    padding: 20px;
    max-width: 95%;
  }

  .event-title-input,
  .info-group input,
  .event-description {
    font-size: 1.1rem;
  }

  .save-event-button {
    font-size: 1.1rem;
  }
}

@media (max-width: 768px) {
  .event-card {
    padding: 15px;
    max-width: 100%;
  }

  .event-info {
    flex-direction: column;
    gap: 15px;
  }

  .event-title-input,
  .info-group input,
  .event-description {
    font-size: 1rem;
    padding: 8px;
  }

  .save-event-button {
    font-size: 1rem;
    padding: 10px 18px;
  }
}

@media (max-width: 480px) {
  .event-card {
    padding: 10px;
  }

  .event-title-input,
  .info-group input,
  .event-description {
    font-size: 0.9rem;
    padding: 7px;
  }

  .save-event-button {
    font-size: 1rem;
    padding: 10px 18px;
  }
}