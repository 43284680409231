.create-study-group-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Dark background with slight opacity */
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.create-study-group-content {
  background: var(--color-light-primary);
  padding: 30px;
  border-radius: 16px; /* More rounded corners */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Enhanced shadow for depth */
  width: 25rem;
  text-align: left;
  position: relative;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  transform: translateY(20px);
  opacity: 0;
  animation: slideUp 0.3s ease-in-out forwards;
}

@keyframes slideUp {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.create-study-group-content .close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.create-study-group-content .close-button:hover {
  transform: scale(1.1);
}

.create-study-group-content h2 {
  margin-bottom: 20px;
  font-size: 22px;
  color: var(--color-light-text);
  font-weight: bold;
}

.create-study-group-content form {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Spacing between form elements */
}

.create-study-group-content input,
.create-study-group-content textarea {
  padding: 12px;
  border: 1px solid var(--color-light-border);
  border-radius: 8px;
  font-size: 16px;
  width: 100%;
  background: var(--color-light-secondary);
  color: var(--color-light-text);
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.create-study-group-content textarea {
  resize: vertical;
  height: 120px;
}

.create-study-group-content label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--color-light-text);
}

.create-study-group-content input[type="checkbox"] {
  margin-left: 16px;
  margin-top: 2px;
  cursor: pointer;
}

.create-study-group-content .form-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.create-study-group-content button {
  padding: 12px 25px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: transform 0.2s, background-color 0.3s ease-in-out;
}

.create-study-group-content button.create-button {
  background-color: var(--color-light-accent);
  color: white;
}

.create-study-group-content button.create-button:hover {
  background-color: var(--color-light-accent-secondary);
  transform: scale(1.05); /* Subtle scaling effect */
}

.create-study-group-content button[type="button"] {
  background-color: var(--color-light-border);
  color: var(--color-light-text);
}

.create-study-group-content button[type="button"]:hover {
  background-color: var(--color-light-accent);
  color: white;
}

/* Toggle switch */
.create-study-group-content input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  width: 40px;
  height: 20px;
  background-color: var(--color-light-border);
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.create-study-group-content input[type="checkbox"]:checked {
  background-color: var(--color-light-accent);
}

.create-study-group-content input[type="checkbox"]::before {
  content: "";
  position: absolute;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: white;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  transition: transform 0.2s ease-in-out;
}

.create-study-group-content input[type="checkbox"]:checked::before {
  transform: translateX(18px) translateY(-50%);
}
