/* CourseComments.css */
.comments-section {
    padding: 40px;
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    margin: 32px auto;
    max-width: 900px;
}

.comments-section h2 {
    font-size: 28px;
    font-weight: 600;
    color: #1a1a1a;
    margin-bottom: 32px;
    text-align: center;
}

.post-body {
    text-align: left !important;
    /* Force left alignment for all comment bodies */
    margin: 0;
    padding: 16px 0;
}

.new-comment-section {
    margin-bottom: 40px;
    background: #f1f3f5;
    border-radius: 16px;
    padding: 24px;
}

.comment-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 40px;
}

.comment-textarea {
    width: 100%;
    min-height: 100px;
    padding: 16px;
    border: 1px solid #d1d9e0;
    border-radius: 10px;
    resize: none;
    font-size: 16px;
    line-height: 1.5;
    color: #495057;
    background: #ffffff;
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.comment-textarea:focus {
    outline: none;
    border-color: #4a90e2;
    box-shadow: 0 0 0 4px rgba(74, 144, 226, 0.1);
}

.comment-textarea::placeholder {
    color: #adb5bd;
}

.comment-submit-btn {
    align-self: flex-end;
    padding: 12px 24px;
    background-color: #4a90e2;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    transition: all 0.2s ease;
}

.comment-submit-btn:hover {
    background-color: #357abd;
    transform: translateY(-1px);
}

.comment-submit-btn:disabled {
    background-color: #e9ecef;
    color: #adb5bd;
    cursor: not-allowed;
    transform: none;
}

.comments-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.comment-item {
    padding: 24px;
    border: 1px solid #e1e8ed;
    border-radius: 16px;
    margin-bottom: 24px;
    background: #ffffff;
    text-align: left;
    /* Ensures entire comment section aligns left */
    transition: all 0.3s ease;
}

.comment-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.comment-header {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 12px;
}

.comment-meta {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.comment-author {
    font-weight: 700;
    color: #1a1a1a;
    font-size: 16px;
}

.comment-date {
    color: #6c757d;
    font-size: 14px;
}

.comment-content {
    margin: 0;
    /* Remove extra margins */
    padding: 16px;
    color: #495057;
    font-size: 16px;
    line-height: 1.7;
    background: #f8f9fa;
    border-radius: 12px;
    text-align: left;
    /* Ensure all comment text aligns left */
}

.loading-comments,
.no-comments {
    text-align: center;
    padding: 40px;
    color: #6c757d;
    font-size: 16px;
    background: #f8f9fa;
    border-radius: 12px;
    margin: 20px 0;
}

.error-message {
    text-align: center;
    padding: 20px;
    color: #dc3545;
    background: #fff5f5;
    border-radius: 12px;
    margin: 20px 0;
    font-size: 15px;
}

.delete-comment-btn {
    padding: 8px 16px;
    background-color: #fff;
    color: #dc3545;
    border: 1px solid #dc3545;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    transition: all 0.3s ease;
}

.delete-comment-btn:hover {
    background-color: #dc3545;
    color: #fff;
}

/* Animation for new comments */
.comment-item {
    animation: fadeIn 0.4s ease;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}