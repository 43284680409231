.application-modal-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    z-index: 1000;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    height: 100%;
    width: 100%;
}

.application-modal-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 24px;
    width: 100%;
    max-width: 500px;
    margin-top: 40px;
    position: relative;
}

.application-modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    background: none;
    border: none;
    font-size: 20px;
    color: #666;
    cursor: pointer;
    padding: 4px 8px;
}

.application-modal-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: center;
}

.application-modal-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.form-label {
    font-size: 14px;
    color: #4B5563;
}

.form-input {
    height: 40px;
    padding: 8px 12px;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    font-size: 14px;
}

.form-textarea {
    height: 100px;
    padding: 8px 12px;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    font-size: 14px;
    resize: none;
}

.form-button-group {
    display: flex;
    gap: 12px;
    margin-top: 8px;
}

.apply-button {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #10B981;
    color: white;
    border: none;
    min-width: 100px;
}

.apply-button:hover {
    background-color: #059669;
}

.cancel-button {
    padding: 8px 16px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: #EF4444;
    color: white;
    border: none;
    min-width: 100px;
}

.cancel-button:hover {
    background-color: #DC2626;
}

/* Add these to your existing ApplicationModal.css */

.error-message {
    background-color: #FEE2E2;
    border: 1px solid #EF4444;
    color: #DC2626;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 14px;
}

.success-message {
    background-color: #D1FAE5;
    border: 1px solid #10B981;
    color: #059669;
    padding: 12px;
    border-radius: 6px;
    margin-bottom: 16px;
    font-size: 14px;
    text-align: center;
}

.form-input:disabled,
.form-textarea:disabled,
.apply-button:disabled,
.cancel-button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.apply-button:disabled {
    background-color: #10B981;
}

.cancel-button:disabled {
    background-color: #EF4444;
}