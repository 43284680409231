.group-description {
  position: absolute;
  top: 100%; /* Position below the study group item */
  left: 0;
  width: 100%;
  background: white;
  color: black;
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.scroll-button {
  /*margin-top: 20px;*/
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em; /* Increased font size */
  color: #007bff;
  transition: color 0.3s;
  height: 60px; /* Match the height of the study group items and post button */
}

.scroll-button:hover {
  color: #0056b3;
}
