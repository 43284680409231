/* Global box-sizing for consistency */
* {
  box-sizing: border-box;
}

.internships-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  background-color: #f4f5f7;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.internships-page .internships-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 1.5%;
  overflow-y: hidden;
  margin-top: 5%;
  margin-left: 15%;
  max-width: 85%;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.internships-page .internships-filters {
  display: flex;
  gap: 1.5%;
  margin-bottom: 2%;
}

/* Modernized Custom Dropdown Styles */
.custom-dropdown {
  position: relative;
  width: 15%; /* Reduced width for narrower filter boxes */
}

.custom-dropdown-button {
  width: 100%;
  padding: 0.75em;
  padding-right: 2em;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
  text-align: left;
  transition: all 0.3s ease;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns%3D%22http%3A//www.w3.org/2000/svg%22 viewBox%3D%220 0 4 5%22%3E%3Cpath fill%3D%22%23000%22 d%3D%22M2 5L0 3h4z%22/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 0.75em center;
  background-size: 0.75em auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-dropdown-button:hover {
  background-color: #f7f7f7;
  border-color: #ccc;
}

.custom-dropdown-button:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.custom-dropdown-options {
  position: absolute;
  width: 100%;
  max-height: 12em;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  z-index: 10;
  margin-top: 0.25em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;
}

.custom-dropdown-options li {
  padding: 0.75em;
  cursor: pointer;
  list-style-type: none;
  transition: background-color 0.2s ease;
}

.custom-dropdown-options li:hover {
  background-color: #f7f7f7;
}

.custom-dropdown-options::-webkit-scrollbar {
  width: 8px;
}

.custom-dropdown-options::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.custom-dropdown-options::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.internships-page .internships-main-content {
  display: flex;
  width: 100%;
  margin-left: 0;
  gap: 2%;
  overflow-x: hidden;
  flex-direction: row;
  padding: 2%;
  background-color: #e9ecef;
  border-radius: 15px;
  flex-grow: 1;
  max-width: 100%;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.internships-page .internships-main-content::-webkit-scrollbar {
  display: none;
}

.internships-page .internships-left-container,
.internships-page .internships-middle-container {
  flex: 1;
  min-width: 25%;
  padding: 2%;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.internships-page .internships-left-container {
  flex: 2;
}

.internships-page .internships-middle-container {
  flex: 3;
}

.internships-page .internships-left-container::-webkit-scrollbar,
.internships-page .internships-middle-container::-webkit-scrollbar {
  display: none;
}

.internships-page h1 {
  font-size: 2.2em;
  color: #333;
  margin-bottom: 1%;
}

.internships-page p {
  font-size: 1.1em;
  color: #666;
}

.internships-page .internships-organization-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  padding: 1.5%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.internships-page .internships-organization-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.internships-page .internships-organization-card h2 {
  font-size: 1.4em;
  color: #333;
}

.internships-page .internships-organization-card p {
  color: #666;
  margin: 0.5em 0;
}

.internships-page .internships-internship-details {
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.internships-page .internships-internship-details ul {
  padding-left: 1.2em;
  margin-bottom: 1em;
  list-style-type: disc;
}

.internships-page .internships-internship-details li {
  margin-bottom: 0.5em;
}

.internships-page .internships-internship-details h2 {
  font-size: 1.4em;
  color: #333;
}

.internships-page .internships-internship-details p {
  color: #666;
  margin: 0.5em 0;
}

/* Modal specific styles */
.internships-page .internships-role-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.internships-page .internships-role-modal-content {
  background-color: #fff;
  padding: 2%;
  border-radius: 15px;
  width: 30%;
  max-width: 30%;
  text-align: center;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.internships-page .internships-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

.internships-page .internships-role-modal-content h2 {
  margin-top: 10px;
  font-size: 1.8rem;
  color: #333;
}

.internships-page .internships-role-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  gap: 10px;
}

.internships-page .internships-role-option {
  text-align: center;
  flex: 1;
}

.internships-page .internships-role-option h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}

/* Add new styles for the header section */
.internships-internship-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  padding-right: 1rem;
}

.internships-internship-header h2 {
  margin: 0;
  font-size: 1.4em;
  color: #333;
}

/* Style for the apply button */
.internships-apply-button {
  background-color: #10B981;
  /* Green color */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-width: 100px;
  text-align: center;
}

.internships-apply-button:hover {
  background-color: #059669;
  /* Darker green on hover */
}

/* Remove any margin-bottom from the h2 inside the header */
.internships-internship-header h2 {
  margin-bottom: 0;
}

.internships-page .internships-create-account-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.internships-page .internships-create-account-button:hover {
  background-color  : #0056b3;
}

/* Media queries for laptop screens */
@media (max-width: 1440px) {
  .internships-page .internships-content {
    margin-left: 250px;
    padding: 15px;
  }


}

/* Media queries for smaller laptops */
@media (max-width: 1024px) {
  .internships-page .internships-content {
    margin-left: 250px;
    padding: 10px;
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .internships-page .internships-content {
    margin-left: 0;
    padding: 2%;
  }

  .internships-page .internships-main-content {
    flex-direction: column;
    padding: 2%;
    gap: 2%;
  }

  .internships-page .internships-left-container,
  .internships-page .internships-middle-container {
    width: 100%;
    min-width: 100%;
  }

  .internships-page .internships-middle-container {
    margin-top: 2%;
  }

  .internships-page .internships-role-modal-content {
    width: 90%; /* Make modal wider on mobile */
    max-width: 90%;
  }
}