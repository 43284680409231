/* Global Styles */
* {
  box-sizing: border-box; /* Apply box-sizing globally for easier sizing */
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}

.login-signup-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 2%;
  background-color: #f5f7fa;
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  animation: gradient-diagonal-animation 60s linear infinite;
  overflow-y: auto;
}

@keyframes gradient-diagonal-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 50%;
  }
}

.login-signup-page .content-container {
  display: flex;
  flex-direction: column; /* Stack content vertically on mobile by default */
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90%;
  gap: 2rem; /* Added gap for consistent spacing between child elements */
}

.login-signup-page .video-container {
  width: 100%;
  max-width: 100%; /* Allow video to take full width on mobile */
  margin-bottom: 1.5%;
}

.login-signup-page .video-container video {
  width: 100%; /* Video scales with container */
  height: auto; /* Maintain aspect ratio */
  /* Removed margin-left: 20%; to ensure the video is centered on smaller screens */
}

.login-signup-page .logo-and-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.login-signup-page .auth-buttons-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Changed from 120% to 100% to prevent overflow */
  max-width: 400px; /* Set a max-width for better appearance on larger screens */
  margin-top: 1%; /* Changed margin top to percentage */
  gap: 1rem; /* Added gap for consistent spacing between buttons */
}

.login-signup-page .divider {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 1.5% 0; /* Changed vertical margin to percentage */
}

.login-signup-page .divider::before,
.login-signup-page .divider::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.login-signup-page .divider span {
  margin: 0 10px;
  color: #111111;
  font-size: 1rem;
}

/* Updated Input Box */
.input {
  width: 100%;
  padding: 0.75rem;
  background-color: rgba(255, 255, 255, 0.15);
  /* Slightly brighter for clarity */
  border: 1px solid rgba(224, 154, 109, 0.4);
  /* Light terracotta border to match theme */
  color: white;
  border-radius: 0.5rem;
  text-align: center;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* Subtle shadow for depth */
}

.input:focus {
  outline: none;
  border-color: rgba(224, 154, 109, 0.8);
  /* Darker border on focus */
  box-shadow: 0 4px 8px rgba(224, 154, 109, 0.4);
  /* Colored shadow on focus */
  background-color: rgba(255, 255, 255, 0.25);
  /* Slightly brighter on focus */
}

.login-signup-page .signup-button,
.login-signup-page .signin-button {
  width: 100%;
  max-width: 100%; /* Allow buttons to take full width within container */
  min-width: 200px;
  margin: 0.8% 0; /* Changed margin to percentage */
  padding: 0.8rem 1.2rem; /* Changed padding to rem for consistency */
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 1rem; /* Changed font size to relative units */
  transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.login-signup-page .signup-button {
  background-color: #f24e1e;
  color: #ffffff;
}

.login-signup-page .signup-button:hover {
  background-color: #d84315;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover/focus */
}

.auth-button:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Enhanced shadow on hover */
}

.login-signup-page .signin-button {
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #ccc;
}

.login-signup-page .signin-button:hover {
  background-color: #f5f5f5;
}

.login-signup-page .signin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5%; /* Changed margin top to percentage */
  max-width: 100%;
  white-space: nowrap;
  width: 100%;
}

.login-signup-page .welcome-message {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
  margin-top: 5%; /* Changed margin top to percentage */
  gap: 0.5rem; /* Added gap for consistent spacing between heading and paragraph */
}

.login-signup-page .welcome-message h2 {
  margin: 0;
  font-size: 1.5rem; /* Changed font size to relative units */
}

.login-signup-page .welcome-message p {
  color: #666;
  margin-top: 2%; /* Changed margin top to percentage */
  font-size: 1rem; /* Changed font size to relative units */
}

/* Media Queries for Responsiveness */

/* Large Desktops and Laptops */
@media (min-width: 1200px) {
  .login-signup-page .content-container {
    max-width: 1100px; /* Increase max-width for larger screens */
    flex-direction: row; /* Side by side layout */
    gap: 4rem; /* Increased gap for larger screens */
  }

  .login-signup-page .video-container {
    max-width: 50%; /* Allocate more space to video on large screens */
    margin-bottom: 0;
  }

  .login-signup-page .video-container video {
    /* Optionally adjust video styling for large screens */
  }

  .login-signup-page .logo-and-buttons {
    align-items: flex-start; /* Align to start on larger screens */
  }

  .login-signup-page .auth-buttons-container {
    max-width: 300px; /* Set a max-width for buttons on large screens */
  }

  .login-signup-page .welcome-message {
    text-align: left;
  }

  .login-signup-page .welcome-message h2 {
    font-size: 2rem; /* Larger font size for headings */
  }

  .login-signup-page .welcome-message p {
    font-size: 1.2rem; /* Larger font size for paragraphs */
  }
}

/* Tablets and Small Desktops */
@media (min-width: 769px) and (max-width: 1199px) {
  .login-signup-page .content-container {
    max-width: 800px; /* Adjust max-width for medium screens */
    flex-direction: row; /* Side by side layout */
    gap: 3rem; /* Adjust gap for medium screens */
  }

  .login-signup-page .video-container {
    max-width: 60%; /* Allocate more space to video */
  }

  .login-signup-page .welcome-message {
    text-align: left;
  }

  .login-signup-page .welcome-message h2 {
    font-size: 1.8rem;
  }

  .login-signup-page .welcome-message p {
    font-size: 1.1rem;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .login-signup-page .content-container {
    flex-direction: column; /* Stack vertically on mobile */
    max-width: 100%;
    gap: 2rem;
  }

  .login-signup-page .video-container {
    max-width: 100%;
    margin-bottom: 1.5%;
  }

  .login-signup-page .welcome-message {
    text-align: center;
  }

  .login-signup-page .auth-buttons-container {
    width: 100%;
    max-width: 100%;
    margin-top: 0.8%;
    gap: 0.8rem;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .login-signup-page {
    padding: 4%; /* Increased padding for very small screens */
  }

  .login-signup-page .auth-buttons-container {
    gap: 1rem;
  }

  .login-signup-page .signup-button,
  .login-signup-page .signin-button {
    font-size: 0.9rem; /* Slightly smaller font on very small screens */
    padding: 0.8rem 1rem;
  }

  .login-signup-page .welcome-message h2 {
    font-size: 1.3rem;
  }

  .login-signup-page .welcome-message p {
    font-size: 0.9rem;
  }
}
