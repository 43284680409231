.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.main-content {
  display: flex;
  flex-grow: 1;
  margin-top: 2%;
  margin-left: 15%;
}

.content {
  display: flex;
  flex-grow: 1;
  transition: margin-left 0.3s;
}

.content.sidebar-open {
  margin-left: 15%;
}

.chat-sidebar {
  width: 25%;
  background: #f8f8f8;
  border-right: 0.1rem solid #ddd;
  padding: 1%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
}

.new-chat-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.chat-search {
  width: 100%;
  padding: 0.5%;
  margin-bottom: 1%;
  border-radius: 0.5rem;
  border: 0.1rem solid #ccc;
}

.chat-list {
  flex-grow: 1;
  overflow-y: auto;
}

.chat-item {
  display: flex;
  align-items: center;
  padding: 1%;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin-bottom: 1%;
  transition: background 0.3s;
  position: relative;
}

.chat-item:hover {
  background: #e6e6e6;
}

.delete-chat-icon {
  color: #ff4d4d;
  cursor: pointer;
  font-size: 1rem;
  position: absolute;
  top: 0.5rem;
  /* Adjust to control vertical spacing */
  right: 0.5rem;
  /* Adjust to control horizontal spacing */
}

.profile-pic {
  width: 12%;
  height: auto;
  border-radius: 50%;
  margin-right: 1%;
  object-fit: cover;
}

.chat-info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.chat-info h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.chat-info p {
  margin: 0.5% 0 0;
  color: #666;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.time {
  color: #999;
  font-size: 0.8rem;
  margin-left: auto;
}

.chat-window {
  flex-grow: 1;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1%;
  border-left: 0.1rem solid #ddd;
}

.chat-window-header {
  display: flex;
  align-items: center;
  margin-bottom: 1%;
}

.chat-window-header .profile-pic {
  width: 15%;
  height: auto;
  margin-right: 1%;
}

.chat-options {
  margin-left: auto;
  display: flex;
  gap: 1rem;
}

.chat-option-icon {
  cursor: pointer;
  font-size: 1.5rem;
  color: #333;
}

.chat-messages {
  height: 65vh;
  overflow-y: auto;
  padding-bottom: 1%;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.message-date {
  display: block;
  text-align: center;
  margin-bottom: 1%;
  font-size: 0.9rem;
  color: #999;
}

.message-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.message {
  display: flex;
  flex-direction: column;
  max-width: 70%;
  margin-bottom: 1%;
  padding: 1%;
  border-radius: 0.5rem;
  word-wrap: break-word;
}

.message.sent {
  align-self: flex-end;
  background: #007bff;
  color: white;
}

.message.received {
  align-self: flex-start;
  background: #f1f1f1;
  color: black;
}

.message p {
  margin: 0;
  font-size: 1rem;
}

.message span {
  display: block;
  margin-top: 0.5%;
  font-size: 0.8rem;
}

.message.sent span {
  color: #eeee
}

.message.received span {
  color: #666
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 1%;
  border-top: 0.1rem solid #ddd;
}

.chat-input input {
  flex-grow: 1;
  padding: 1%;
  border: 0.1rem solid #ccc;
  border-radius: 0.5rem;
  margin-right: 0.5%;
}

.chat-input button {
  padding: 1% 3%;
  border: none;
  border-radius: 0.5rem;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.chat-input button:hover {
  background-color: #0056b3;
}

.input-icon {
  font-size: 1.5rem;
  margin-right: 0.5%;
  cursor: pointer;
  color: #333;
}

.no-chat-selected {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-size: 1.2rem;
  color: #666;
}

@media (max-width: 768px) {
  .content {
    flex-direction: column;
    margin-left: 0;
  }

  .content.sidebar-open {
    margin-left: 0;
  }

  .chat-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 0.1rem solid #ddd;
  }

  .chat-window {
    padding: 0.5%;
  }

  .chat-window-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .chat-options {
    margin-top: 1rem;
  }
}