/* Base container styles */
.pagination_container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

/* Skip buttons and navigation buttons */
.pagination_container .big_skip .pg_button,
.pagination_container .small_skip .pg_button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
  font-size: 0.9rem;
  padding: 0.4rem 0.8rem;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination_container .big_skip .pg_button .pg-text,
.pagination_container .small_skip .pg_button .pg-text {
  display: inline;
}

.pg_button .pg-text {
  margin-top: -2.5px;
}

/* Pagination buttons */
.pagination_container .pagination {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 0;
  margin: 0;
  list-style: none;
}

.pagination_container .pg {
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pagination_container .pg:hover {
  background: var(--color-light-accent2-secondary);
  color: white;
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

/* Selected page button */
.pagination_container .pg_button.selected {
  background-color: var(--color-light-accent2);
  color: white;
  transform: scale(1.1);
}

/* Disabled buttons */
.pagination_container .pg_button.disabled {
  background: #e0e0e0;
  color: #999;
  cursor: not-allowed;
}

@media (max-width: 1760px) {
  .pagination_container .big_skip .pg_button .pg-text,
  .pagination_container .small_skip .pg_button .pg-text {
    display: none;
  }

  /* Adjust the button styles to better fit the icons */
  .pagination_container .big_skip .pg_button,
  .pagination_container .small_skip .pg_button {
    font-size: 1rem;
    padding: 0.2rem;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
  }

  .pagination_container .pg {
    font-size: 0.7rem;
    width: 1.75rem;
    height: 1.75rem;
  }
}
