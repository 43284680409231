/* Global Box-sizing */
html {
  box-sizing: border-box;
  font-size: 16px;
  /* Base font-size for scaling */
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#root {
  height: 100%;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
  overflow-x: hidden;
  /* Prevent horizontal scroll */
  overflow-y: auto;
  /* Allow vertical scrolling */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content,
.top-nav-bar {
  overflow: visible;
}

.content {
  flex-grow: 1;
  display: flex;
  position: relative;
}

.section-divider {
  border-bottom: 0.0625rem solid #ccc;
  /* 1px */
  margin: 1.25rem 0;
  /* 20px */
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.questions-list {
  flex-grow: 1;
  margin-left: 1rem;
  /* 16px */
  margin-right: 1rem;
  /* 16px */
}

/* Fixed vertical lines */
.fixed-vertical-line {
  position: fixed;
  top: 6.1875rem;
  /* 99px */
  bottom: 0;
  left: 15.625rem;
  /* 250px */
  width: 0.0625rem;
  /* 1px */
  background-color: #ccc;
  z-index: 200;
}

.fixed-vertical-line.right {
  left: calc(100% - 18.75rem);
  /* 300px */
}

/* Sidebar and main content adjustments for larger screens */
.sidebar-open .content {
  margin-left: 15.625rem;
  /* 250px */
}

.main-content {
  margin-left: 15.625rem;
  /* 250px */
  padding: 1.25rem;
  /* 20px */
  padding-top: 4.375rem;
  /* 70px */
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {

  /* Reset margins and padding for smaller screens */
  .main-content {
    margin-left: 0;
    padding: 0.625rem;
    /* 10px */
    padding-top: 3.75rem;
    /* 60px */
  }

  .fixed-vertical-line {
    display: none;
  }

  /* Flexbox adjustments for smaller screens */
  .content {
    flex-direction: column;
  }

  .questions-list {
    margin-left: 0.5rem;
    /* 8px */
    margin-right: 0.5rem;
    /* 8px */
  }
}

@media (min-width: 769px) {
  .main-content {
    margin-left: 15.625rem;
    /* 250px */
  }

  .fixed-vertical-line {
    display: block;
  }
}

/* Additional media queries for even smaller screens */
@media (max-width: 576px) {
  .main-content {
    padding: 0.3125rem;
    /* 5px */
    padding-top: 3.125rem;
    /* 50px */
  }

  .questions-list {
    margin-left: 0.25rem;
    /* 4px */
    margin-right: 0.25rem;
    /* 4px */
  }

  main {
    align-items: flex-start;
  }
}

/* Large Screens and High-Resolution Devices */
@media (min-width: 1200px) {
  html {
    font-size: 80%;
    /* Reduce zoom effect for larger screens */
  }

  .main-content {
    padding: 2rem;
    /* Increase padding for large screens */
  }
}

/* Fluid Typography (Optional) */
@media (min-width: 576px) {
  html {
    font-size: 112.5%;
    /* 18px */
  }
}

@media (min-width: 768px) {
  html {
    font-size: 125%;
    /* 20px */
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 140%;
    /* 22.4px */
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;