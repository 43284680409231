.questions-list {
  margin-top: 5px;
}

.sort-options {
  display: flex;
  align-items: center;
}

.sort-options label {
  margin-right: 10px;
  display: flex;
  align-items: center;
}

.sort-options select {
  padding: 5px;
  margin-left: 10px; /* Ensure space between the label and the select element */
  margin-bottom: 18px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

#no_qs_notice {
	margin: 0 auto;
	text-align: center;
}

#no_qs_notice span {
	font-size: 1.15em;
	color: #777777;
}

.question-thread-wrapper {
  transition: all 0.3s ease-out;
}

.question-thread-wrapper.highlighted {
  animation: highlight-post 3s ease-out;
}

@keyframes highlight-post {
  0% {
    background-color: rgba(255, 223, 186, 0.5);
    box-shadow: 0 0 15px rgba(255, 169, 77, 0.5);
    transform: scale(1.01);
  }

  100% {
    background-color: transparent;
    box-shadow: none;
    transform: scale(1);
  }
}