.login-signup-logo {
  width: 100%; /* Converted from 250px to 25% */
  height: auto;
  text-align: center;
  margin-bottom: 2%; /* Converted from 20px to 2% */
}

.login-signup-logo-image {
  width: 100%; /* Converted from 250px to 25% */
  height: auto;
  max-width: 100%; /* Ensure it scales responsively */
}
