/* Base font and layout */
body {
  margin: 0;
  padding: 0;
  font-family: "Inter", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif; /* Or your preferred font */
  background-color: #f6f7f9;
}

/* Courses Page Container */
.courses-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

/* The main content area */
.courses-page-content {
  flex-grow: 1;
  margin-left: 15%; /* Remove this when sidebar is fix */
  margin-top: 2%;
  padding: 2%;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 0.75em;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.05);
  transition: margin-left 0.3s;
}

/* Filters Section */
.courses-page-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

/* Filter Dropdown Container */
.filter-dropdown {
  position: relative;
  min-width: 180px;
  cursor: pointer;
  font-size: 0.85rem;
}

.filter-label {
  font-size: 0.85rem;
  padding: 0.8em 1.2em;
  border-radius: 0.5em;
  background-color: #fff;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dropdown-icon {
  transition: transform 0.3s ease;
}

.dropdown-icon.open {
  transform: rotate(180deg);
}

/* Filter Options (the dropdown menu) */
.filter-options {
  position: absolute;
  top: calc(100% + 0.2rem);
  left: 0;
  right: 0;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 0.5em;
  background-color: #fff;
  z-index: 999;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.filter-option {
  padding: 0.8em 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #333;
}

.filter-option:hover {
  background-color: #efefef;
}

/* Course List (Grid Layout) */
.courses-page-course-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
}

.courses-page-course-item {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.5em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  overflow: hidden;
  max-width: 100%;
  min-width: 0;
  width: 100%;
}

.courses-page-course-item:hover {
  transform: translateY(-3px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Course Thumbnail */
.courses-page-course-thumbnail {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 ratio for the thumbnail */
  overflow: hidden;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  background-color: #e2e2e2; /* fallback background color */
}

.courses-page-course-thumbnail img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lock-icon,
.unlock-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.2em;
}

/* Course Info Row */
.courses-page-course-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  gap: 1rem;
  flex-grow: 1;
  flex-wrap: wrap;
  align-items: start;
  justify-content: flex-start;
}

/* Profile Picture (Avatar) */
.courses-page-course-info .profile-picture {
  flex-shrink: 0;
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  border-radius: 50%;
  object-fit: cover;
}

/* Course Details */
.courses-page-course-details {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  min-width: 0; /* allow flex item to shrink */
  flex-grow: 1;

  /* Prevent text overflow: */
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.creator-title-price {
  display: flex;
  flex-direction: column; /* Stack title and author vertically */
  flex-grow: 1; /* Allow this section to grow and occupy available space */
  overflow: hidden; /* Prevent content from overflowing */
}

.courses-page-course-details h2 {
  font-size: 0.9rem;
  font-weight: bold;
  color: #333;
  margin: 0;
  display: -webkit-box; /* Required for line clamping */
  -webkit-line-clamp: 2; /* Clamp to 2 lines */
  -webkit-box-orient: vertical; /* Specify vertical layout for clamping */
  overflow: hidden; /* Hide overflowing text */
  text-overflow: ellipsis; /* Add ellipses (...) */
  max-width: 15ch;
}

.courses-page-course-details .course-price-and-creator {
  display: flex; /* Enables Flexbox */
  align-items: center; /* Align items vertically */
  gap: 0.75rem; /* Spacing between logo and text */
}

.courses-page-creator-name {
  font-size: 0.75rem;
  color: #777;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any overflowing text */
  text-overflow: ellipsis; /* Add ellipses (...) to the truncated text */
  max-width: 150px; /* Adjust to your desired maximum width */
}

.courses-page-price {
  font-weight: 600;
  color: #2c5282;
  padding: 2px 8px;
  background-color: #ebf8ff;
  border-radius: 4px;
  font-size: 0.75em;
}

/* Rating Stars */
.courses-page-rating {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: auto;
}

.courses-page-rating span {
  font-size: 0.55rem;
  color: #555;
}

.courses-page-join-share-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Ensures vertical alignment */
  width: 100%;
}

/* Share Icon */
.courses-page-share-icon {
  margin-left: auto;
  color: #007bff;
  cursor: pointer;
  transition: color 0.3s ease;
}

.courses-page-share-icon:hover {
  color: #0056b3;
}

/* Join/Enrolled Button */
.join-course-button,
.enrolled-button {
  padding: 0.4em 0.8em;
  border-radius: 0.5em;
  border: none;
  outline: none;
  font-size: 0.9rem;
  cursor: pointer;
  flex-shrink: 0;
}

.join-course-button {
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.3s ease;
}

.join-course-button:hover {
  background-color: #0056b3;
}

.enrolled-button {
  background-color: #bbb;
  color: #fff;
}

/* Loading & Error */
.loading {
  text-align: center;
  margin: 1.5rem 0;
  font-size: 1rem;
  color: #666;
}

.error {
  text-align: center;
  margin: 1.5rem 0;
  font-size: 1rem;
  color: #e53e3e; /* red-ish */
}

/* Modals (Join, Payment, Role) */
.join-course-modal,
.payment-modal,
.courses-page-role-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1em; /* so the modal can be scrolled on very small screens */
  box-sizing: border-box;
}

.join-course-modal-content,
.payment-modal-content,
.courses-page-role-modal-content {
  position: relative;
  background-color: #fff;
  border-radius: 0.75em;
  padding: 2rem;
  max-width: 450px; /* wide enough for comfortable reading, but not too wide */
  width: 100%;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* Close Button (X) */
.close-button,
.courses-page-close-button {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #666;
}

.close-button:hover,
.courses-page-close-button:hover {
  color: #333;
}

/* Confirm or CTA buttons in modals */
.confirm-button {
  background-color: #007bff;
  color: #fff;
  border-radius: 0.4em;
  border: none;
  padding: 0.6em 1em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.confirm-button:hover {
  background-color: #0056b3;
}

/* Payment form in the Payment Modal */
.payment-modal-content form {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Stripe CardElement container - you can style further if needed */
.payment-modal-content .StripeElement {
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 0.4em;
}

/* Payment submit button */
.payment-modal-content button[type="submit"] {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 0.4em;
  padding: 0.6em 1em;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  align-self: flex-end;
}

.payment-modal-content button[type="submit"]:hover {
  background-color: #0056b3;
}

/* Role Modal */
.courses-page-role-modal-content {
  text-align: center;
}

.courses-page-role-options {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.courses-page-role-option {
  margin: 1rem;
}

.courses-page-role-option h3 {
  margin-bottom: 0.5rem;
}

.courses-page-create-account-button {
  background-color: #0056b3;
  color: #fff;
  border: none;
  border-radius: 0.4em;
  padding: 0.5em 1em;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
}

.courses-page-create-account-button:hover {
  background-color: #003f8a;
}

@media (max-width: 1280px) {
  .courses-page-content {
    margin-left: 25%; /* Remove this when sidebar is fix */
    margin-top: 5%;
  }
}

@media (max-width: 1024px) {
  .courses-page-content {
    margin-left: 30%; /* Remove this when sidebar is fix */
    margin-top: 10%;
  }
  .filter-label {
    font-size: 0.75rem;
  }
}

@media (max-width: 768px) {
  .courses-page-content {
    margin-left: 0%; /* Remove this when sidebar is fix */
    margin-top: 20%;
  }

  .courses-page-course-list {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
}

/* Media Queries */
@media (max-width: 480px) {
  .courses-page-content {
    margin-top: 30%;
  }
  .filter-dropdown {
    min-width: 140px; /* narrower to fit smaller screens */
  }

  .filter-label {
    padding: 0.6em 0.8em;
    font-size: 0.85rem;
  }

  .courses-page-course-info {
    padding: 0.8em;
    gap: 0.5rem;
  }

  .courses-page-course-details h2 {
    font-size: 1rem;
  }

  .courses-page-course-details .course-price-and-creator {
    flex-direction: column;
    align-items: flex-start;
  }

  .join-course-button,
  .enrolled-button {
    margin-top: 0.5rem;
  }
}
