/* Define CSS variables for easy theming */
:root {
  --text-color: #333;
  --border-color: #ccc;
  --border-radius: 15px;
  --box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* Post Form Wrapper */
.post-form-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* Post Form Container */
.post-form-container {
  background-color: var(--color-light-primary);
  padding: 30px;
  border-radius: var(--border-radius);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  width: 100%;
}

/* Form */
form {
  display: flex;
  flex-direction: column;
}

/* Input Fields */
.post-input,
.tags-input {
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 15px;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
  font-size: 1rem;
  color: var(--text-color);
  background-color: var(--color-light-primary);
  transition: border-color 0.3s ease;
}

.post-input:focus,
.tags-input:focus {
  outline: none;
  border-color: var(--color-light-accent2);
}

.post-input {
  min-height: 120px;
  resize: vertical;
}

/* Form Footer */
.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Media Upload */
.media-upload {
  display: flex;
  align-items: center;
  gap: 10px;
}

.media-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 10px 15px;
  transition: color 0.3s ease;
  background-color: var(--color-light-border);
  border-radius: var(--border-radius);
}

.media-button:hover {
  color: var(--color-light-accent2);
}

.media-button input[type="file"] {
  display: none;
}

.media-icon {
  font-size: 1.5rem;
}

.media-name {
  font-size: 0.9rem;
  color: var(--text-color);
}

.post-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-light-accent);
  color: var(--color-light-primary);
  font-size: 1rem;
  font-weight: bold;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
}

.post-button span {
  font-size: 1rem;
}

.post-button:hover {
  background-color: var(--color-light-accent2);
  color: var(--color-light-primary);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.post-button:active {
  background: var(--color-light-accent2-secondary);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
}
