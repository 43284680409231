/* Login Page Container */
.login-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 5%;
  overflow-y: auto;
  scale: 1;
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f5f7fa;
  background-image: linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px),
    linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 20px 20px;
  animation: gradient-diagonal-animation 60s linear infinite, fade-in 1s ease;
}

@keyframes gradient-diagonal-animation {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 100% 100%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/* Login Form Wrapper */
.login-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 4%;
  border-radius: 16px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
  background-image: linear-gradient(white, white);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Logo Section */
.login-logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-logo-image {
  width: 150px;
  height: auto;
}

/* Welcome Back Text */
.login-welcome-back {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  padding: 10px 15px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25),
    0px 0px 10px rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  display: inline-block;
}

.login-welcome-back:hover {
  color: #222;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.3),
    0px 0px 12px rgba(255, 255, 255, 0.6);
  transition: color 0.3s ease, text-shadow 0.3s ease;
}

/* Waving hand animation */
.login-welcome-back::before {
  content: "👋 ";
  display: inline-block;
  animation: wave 1.5s infinite ease-in-out;
  transform-origin: 70% 70%;
}

/* Keyframes for the waving effect */
@keyframes wave {
  0%,
  100% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  40% {
    transform: rotate(10deg);
  }

  60% {
    transform: rotate(-10deg);
  }

  80% {
    transform: rotate(5deg);
  }
}

/* Form Styles */
.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login-form-input {
  width: 100%;
  padding: 10px 15px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 30px;
  background-color: #f7f7f7;
  font-size: 1rem;
}

/* Password Input Container */
.login-password-input-container {
  position: relative;
  width: 100%;
}

.login-password-input-container input {
  width: 100%;
  padding: 10px 45px 10px 15px;
  border-radius: 30px;
  border: 1px solid #ccc;
  background-color: #f7f7f7;
  font-size: 1rem;
}

/* Toggle Password Button */
.login-toggle-password-button {
  position: absolute;
  top: 51%;
  right: 0%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2rem;
  background-color: transparent !important;
  border: none !important;
  outline: none !important;
}

.login-toggle-password-button:hover {
  color: #555;
}

.login-toggle-password-button svg {
  opacity: 0.7;
  transform: translateX(-15px);
}

/* Options Container */
.login-options-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 15px 0;
  align-items: center;
}

.login-keep-signed-in {
  display: flex;
  align-items: center;
  white-space: nowrap !important;
  gap: 2px;
  font-size: 20px !important;
}

.login-page-container .login-keep-signed-in input {
  margin-right: 10px;
}

.login-page-container .login-forgot-password-link {
  text-decoration: none;
  color: #6b7280;
  opacity: 0.8;
  font-size: 0.9rem;
  transition: opacity 0.2s ease;
}

.login-forgot-password-link:hover {
  text-decoration: underline;
}

/* Submit Button */
.login-submit-button {
  width: 100%;
  padding: 12px;
  background-color: #f24e1e;
  color: #ffffff;
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  border-radius: 30px;
  transition: background-color 0.3s;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
}

.login-submit-button:hover {
  background-color: #d84315;
}

/* Responsive Styles */

/* Large Desktops */
@media (min-width: 1440px) {
  .login-form-wrapper {
    max-width: 600px;
    padding: 4%;
  }

  .login-welcome-back {
    font-size: 1.8rem;
  }

  .login-toggle-password-button {
    font-size: 1.4rem;
  }

  .login-submit-button {
    font-size: 1.3rem;
  }
}

/* Tablets and Small Desktops */
@media (max-width: 1024px) {
  .login-form-wrapper {
    max-width: 500px;
  }

  .login-welcome-back {
    font-size: 1.8rem;
  }

  .login-toggle-password-button {
    font-size: 1.4rem;
  }

  .login-submit-button {
    font-size: 1.2rem;
  }
}

/* Mobile Devices */
@media (max-width: 768px) {
  .login-page-container {
    padding: 10%;
    scale: 1;
  }

  .login-form-wrapper {
    max-width: 100%;
    padding: 5%;
  }

  .login-logo-image {
    width: 120px;
  }

  .login-welcome-back {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }

  .login-form-input {
    padding: 12px 20px;
    margin: 10px 0;
    font-size: 1rem;
  }

  .login-toggle-password-button {
    font-size: 1.5rem;
  }

  .login-options-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .login-keep-signed-in {
    width: 100%;
    margin-bottom: 10px;
  }

  .login-forgot-password-link {
    font-size: 1rem;
  }

  .login-submit-button {
    padding: 14px;
    font-size: 1.3rem;
    margin-top: 25px;
  }
}

/* Small Mobile Devices */
@media (max-width: 480px) {
  .login-page-container {
    padding: 10%;
    scale: 1;
  }

  .login-form-wrapper {
    padding: 10%;
  }

  .login-logo-image {
    width: 100px;
  }

  .login-welcome-back {
    font-size: 1.4rem;
  }

  .login-form-input {
    padding: 14px 25px;
    font-size: 1rem;
  }

  .login-toggle-password-button {
    font-size: 1.8rem;
  }

  .login-submit-button {
    padding: 16px;
    font-size: 1.5rem;
  }
}
