/* Main Layout */
.course-page {
  min-height: 100vh;
  background-color: #f0f2f5;
}

.course-content {
  padding: 20px;
  max-width: 1800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;
}

.title-container {
  flex: 1;
  text-align: left;
  /* Ensure title stays left-aligned */
}

.title-container h1 {
  margin: 0;
  text-align: left;
}

.course-header {
  margin-bottom: 1.5rem;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.header-content h1 {
  margin: 0;
  flex: 1;
}

.enroll-btn-header {
  padding: 0.75rem 1.5rem;
  background-color: #10b981;
  /* Updated green color */
  color: white;
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.enroll-btn-header:hover {
  background-color: #059669;
}

.media-section {
  flex: 1;
  /* Add this */
  min-width: 0;
  /* Add this */
}

.course-profile-content {
  margin-left: 250px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  margin-top: 50px;
  display: flex;
  /* Add this */
  gap: 24px;
  /* Add this */
}

/* Update the course-sections class */
.course-sections {
  width: 320px;
  /* Add this */
  height: fit-content;
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  flex-shrink: 0;
  /* Add this */
}

.media-player {
  width: 100%;
  aspect-ratio: 16/9;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 10px;
}


.main-content-wrapper {
  display: flex;
  gap: 24px;
  margin-top: 20px;
}

/* Left Side - Video */
.video-section {
  flex: 1;
  min-width: 0;
}

/* Left Column */
.content-left {
  width: calc(100% - 320px - 16px);
  /* Total width minus sidebar and gap */
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.video-container {
  width: 100%;
  background: #000;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.video-player {
  width: 100%;
  aspect-ratio: 16/9;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.course-subsection {
  padding: 10px 16px 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.course-subsection:hover {
  background-color: #f8f9fa;
}

.course-subsection.active {
  background-color: #007bff;
  color: white;
}

/* Course Information Below Video */
.course-info {
  flex: 1;
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.course-info h1 {
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.instructor-info {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.instructor-details {
  flex: 1;
  text-align: left;
}

.instructor-name {
  display: block;
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
  margin-bottom: 4px;
  text-align: left;
}

.course-stats {
  font-size: 0.9em;
  color: #666;
}

.follow-btn {
  padding: 8px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 80px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.follow-btn:hover {
  background-color: #0056b3;
}

/* Course Meta Information */
.course-meta {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 24px 0;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 8px;
}

.meta-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.meta-item label {
  font-size: 0.9em;
  color: #666;
}

.meta-item span {
  font-size: 1.1em;
  font-weight: 500;
  color: #333;
}

/* Rating Section */

.rating-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 24px 0;
  text-align: right;
}

.rating-label {
  font-size: 1.2em;
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
}

.rating-section {
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 24px 0;
}

.rating-star {
  cursor: pointer;
  transition: transform 0.2s;
}

.rating-star:hover {
  transform: scale(1.1);
}

.rating-value {
  font-size: 1.2em;
  font-weight: 500;
  color: #333;
}

/* Course Description */
.course-description {
  margin: 24px 0;
}

.course-description h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 16px;
  color: #1a1a1a;
  text-align: left;
}

.course-description p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #444;
  text-align: left;
}

/* Enrollment Button */
.enroll-btn {
  width: 100%;
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin: 24px 0;
}

.enroll-btn:hover {
  background-color: #218838;
}

/* Comments Section */
.comments-section {
  margin-top: 32px;
}

.comments-section h2 {
  font-size: 1.5em;
  font-weight: 600;
  margin-bottom: 20px;
}

.comment-input {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.comment-input textarea {
  width: 100%;
  min-height: 100px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  resize: none;
  font-size: 1em;
  color: #333;
  background-color: #f8f9fa;
}

.comment-input textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.comment-input button {
  align-self: flex-end;
  padding: 10px 24px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.comment-input button:hover {
  background-color: #0056b3;
}

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.comment-item {
  display: flex;
  gap: 16px;
  padding: 16px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #eee;
}

.comment-body {
  flex: 1;
}

.comment-author {
  display: block;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

/* Right Sidebar - Course Content */
.content-right {
  width: 320px;
  flex-shrink: 0;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.course-sections h2 {
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  background-color: #333333;
  /* Dark grey */
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.section-duration-lock {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lock-icon {
  font-size: 16px;
}

.lock-icon.locked {
  color: #dc2626;
  /* red for locked content */
}

.lock-icon.unlocked {
  color: #16a34a;
  /* green for free/unlocked content */
}

.intro-section {
  background-color: #f0f7ff;
}

.free-preview-badge {
  font-size: 0.75rem;
  color: #16a34a;
  background-color: #dcfce7;
  padding: 2px 8px;
  border-radius: 4px;
  margin-left: 8px;
}

.sections-container {
  flex: 1;
  overflow-y: auto;
  padding: 0;
}

.sections-container h2 {
  padding: 16px 20px;
  font-size: 1.2em;
  font-weight: 600;
  border-bottom: 1px solid #eee;
  background: #f8f9fa;
  margin: 0;
  position: sticky;
  top: 0;
  z-index: 1;
}

.section {
  border-bottom: 1px solid #eee;
}



.section-header {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
}

.section-header:hover {
  background-color: #f8f9fa;
}

.section.active .section-header {
  background-color: #e9ecef;
}

.section-header h3 {
  font-size: 1em;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.subsections {
  background-color: #f8f9fa;
}

.subsection {
  padding: 10px 16px 10px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 0.9em;
  color: #666;
}

.subsection:hover {
  background-color: #e9ecef;
}

.subsection.active {
  background-color: #007bff;
  color: white;
}

/* Sidebar Open State */
.sidebar-open .course-content {
  margin-left: 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .content-left {
    width: calc(100% - 280px - 16px);
  }

  .content-right {
    width: 280px;
  }
}