.post-page {
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-top: 25px;
    overflow: hidden;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    margin-left: 250px;
    padding: 20px;
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .content.sidebar-open {
    margin-left: 250px; /* Adjust to match the width of your sidebar */
  }
  
  .main-section {
    flex-grow: 1;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  