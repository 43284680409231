.internship-creation-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow-y: scroll; /* Enable vertical scrolling */
  margin-top: 1.4%;
  margin-left: 7%;
  margin-right: auto;
  scrollbar-width: none; /* Hide scrollbar in Firefox */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.internship-creation-page-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit browsers (Chrome, Safari) */
}

.internship-creation-content-wrapper {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 1.2%;
  padding: 2%;
  max-width: 82%;
  margin: 4% auto -2% auto;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: 'Roboto', sans-serif;
  position: relative;
  overflow: visible; /* Ensure the wrapper doesn't hide overflow */
}

.internship-creation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1%;
  width: 100%;
}

.internship-creation-header h2 {
  font-size: 1.2em;
  color: #333;
  margin: 0;
}

.internship-close-icon {
  cursor: pointer;
  font-size: 1.2em;
  color: #ff0000;
}

.internship-form-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden; /* No scrolling within the form container */
  flex-grow: 1;
}

.internship-form-group {
  flex: 0 0 48%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5%;
}

.internship-form-group label {
  font-size: 0.9em;
  color: #333;
  margin-bottom: 0.5%;
}

.internship-form-group input,
.internship-form-group select,
.internship-form-group textarea {
  padding: 0.8%;
  border: 0.1em solid #ccc;
  border-radius: 0.8em;
  font-size: 0.9em;
  color: #333;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.internship-form-group input:focus,
.internship-form-group select:focus,
.internship-form-group textarea:focus {
  border-color: #5c6bc0;
  outline: none;
}

.internship-form-group textarea {
  resize: none;
  height: 10em;
  overflow-y: auto;
}

.internship-prerequisites-group {
  flex: 0 0 100%;
  position: relative; /* Position relative to avoid affecting other elements */
  margin-bottom: 1.5%; /* Separation from the next section */
}

.internship-prerequisites-list {
  margin-top: 1%;
  max-width: 48%;
  max-height: 150px; /* Fixed height for the list */
  overflow-y: auto; /* Enable scrolling within the list */
  border: 1px solid #ccc;
  border-radius: 0.8em;
  padding: 0.5%; /* Padding to give space within the list */
  background-color: #f9f9f9;
  position: relative; /* Ensure it doesn't affect other sections */
}

.internship-checkbox-group-centered {
  display: flex;
  align-items: center;
  margin-bottom: 1.5%;
  text-align: center;
  margin-top: 0.5%; /* Keep "Require Resume" in place */
  position: relative; /* Prevent movement from other elements */
}

.internship-form-group.textarea-group {
  flex: 0 0 100%;
  margin-top: -1.0%; /* Keep "About the Job" section in place */
  position: relative; /* Prevent movement from other elements */
}

.prerequisite-buttons {
  display: flex;
  gap: 1%;
}

.internship-add-prerequisite-button {
  background-color: #007bff !important;
  color: white;
  border: none;
  border-radius: 0.8em;
  padding: 0.8%;
  cursor: pointer;
  font-size: 0.9em;
  margin-bottom: 0.5%;
  transition: background-color 0.3s ease;
}

.internship-add-prerequisite-button:hover {
  background-color: #0056b3;
}

.internship-prerequisite-item {
  background-color: #f1f1f1;
  padding: 0.5% 1%;
  border-radius: 0.8em;
  margin-bottom: 0.5%;
  font-size: 0.85em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.internship-remove-icon {
  cursor: pointer;
  font-size: 1em;
  color: #ff0000;
  margin-left: 1%;
}

.internship-create-button {
  padding: 1%;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 0.8em;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s ease;
  align-self: center;
  margin-top: 0%;
}

.course-dropdown {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f1f1f1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-height: 100%; /* Limit height */
  overflow-y: auto; /* Scroll if there are too many items */
  position: absolute;
  width: 36%; /* Match the width of the input */
  z-index: 1000;
}

.course-dropdown li {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.course-dropdown li:hover {
  background-color: #0056b3;
  color: white;
}

.course-dropdown li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

/* Custom scrollbar styling */
.course-dropdown::-webkit-scrollbar {
  width: 8px;
}

.course-dropdown::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 8px;
}

.course-dropdown::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 8px;
}

.course-dropdown::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Modal Styles */
.internship-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.internship-modal-content {
  background-color: #fff;
  padding: 2%;
  border-radius: 0.8em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 40%;
  width: 100%;
  text-align: center;
}

.internship-modal-content h3 {
  font-size: 1.2em;
  margin-bottom: 1.5%;
}

.internship-modal-content input[type="text"] {
  width: 100%;
  padding: 0.8%;
  margin-bottom: 1.5%;
  border: 0.1em solid #ccc;
  border-radius: 0.8em;
  font-size: 0.9em;
}

.internship-modal-add-button,
.internship-modal-cancel-button,
.internship-modal-ok-button {
  padding: 1%;
  border: none;
  border-radius: 0.8em;
  font-size: 0.9em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.internship-modal-add-button {
  background-color: #007bff;
  color: white;
  margin-right: 1%;
}

.internship-modal-add-button:hover {
  background-color: #0056b3;
}

.internship-modal-cancel-button {
  background-color: #ccc;
  color: #333;
}

.internship-modal-cancel-button:hover {
  background-color: #999;
}

.internship-modal-ok-button {
  background-color: #f44336;
  color: white;
}

.internship-modal-ok-button:hover {
  background-color: #d32f2f;
}