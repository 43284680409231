.spinner--container {
    display: inline-block;
    height: 35px;
    /* Default smaller height */
    overflow: hidden;
    width: 100%;
}

.spinner--list {
    position: relative;
    height: 100%;
    width: 100%;
}

.spinner--list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: absolute;
    width: 100%;
    will-change: transform;
}

.spinner--list h1 {
    font-size: 25px;
    line-height: 1.2;
    margin: 0;
    font-weight: bold;
    color: #333;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    text-align: middle;
    width: 100%;
}

/* Medium screens */
@media (min-width: 768px) {
    .spinner--container {
        height: 40px;
    }

    .spinner--list h1 {
        font-size: 30px;
        line-height: 1.3;
        text-align: left;
    }
}

/* Large screens */
@media (min-width: 1250px) {
    .spinner--container {
        height: 60px;
    }

    .spinner--list h1 {
        font-size: 45px;
        line-height: 1.5;
        text-align: left;
    }
}